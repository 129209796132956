import http from "../http-common";

// //license_form
// Route::post('license_form/store', [LicenseFormController::class, 'store'])->middleware(['permission:license_form-store']);
// Route::get('license_form/index', [LicenseFormController::class, 'index'])->middleware(['permission:license_form-index']);
// Route::get('license_form/show/{id}', [LicenseFormController::class, 'show'])->middleware(['permission:license_form-show']);
// Route::put('license_form/active/{id}', [LicenseFormController::class, 'active'])->middleware('permission:license_form-active');
// Route::put('license_form/inactive/{id}', [LicenseFormController::class, 'inactive'])->middleware('permission:license_form-inactive');
// Route::put('license_form/update/{id}', [LicenseFormController::class, 'update'])->middleware('permission:license_form-update');
// Route::get('license_form/indexRequests', [LicenseFormController::class, 'indexLicenseFormRequests'])->middleware(['permission:license_form-request']);
// Route::put('license_form/active/{id}', [LicenseFormController::class, 'setStatusActive'])->middleware(['permission:license_form-status']);
// Route::put('license_form/inactive/{id}', [LicenseFormController::class, 'setStatusInactive'])->middleware(['permission:license_form-status']);

// //});

// //Dashboard
// Route::get('dashboard/count', [DashboardController::class, 'licenseCountData']);
// Route::get('dashboard/data/{id}', [DashboardController::class, 'licenseFormData']);

// Route::get('refreshAuth', [AuthController::class, 'refresh']);

// Route::get('dashboard/count', [DashboardController::class, 'licenseCountData']);
// Route::get('dashboard/data/{id}', [DashboardController::class, 'licenseFormData']);

class DashboardDataService {
  getTotalizadores(requestParams) {
    return http.get("/dashboard/count", {params : requestParams});
  }
  getLicenseFormData(id) {
    return http.get(`/dashboard/data/${id}`);
  }
  getBarChartData(requestParams) {
    return http.get("/dashboard", {params : requestParams});
  }

}
export default new DashboardDataService();