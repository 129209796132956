<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <subtipos-table />
        </div>
      </div>
      
    </div>
  </template>
  
  <script>
  import SubtiposTable from "./components/SubtiposTable.vue";
  
  export default {
    name: "subtipos",
    components: {
      SubtiposTable,
    },
  };
  </script>
  