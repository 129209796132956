<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <licencas-forms-table />
      </div>
    </div>
    
  </div>
</template>

<script>
import LicencasFormsTable from "./components/LicencasFormsTable";

export default {
  name: "licencasforms",
  components: {
    LicencasFormsTable,
  },
};
</script>
