<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <h6>Lista de Perfis / Permissões</h6>
    </div>

    <div style="text-align:right; margin-right: 1%; margin-top: -3%;">
      <a href="#"
        class="btn btn-success"
        @click="openCreateModal"
        >
        <font-awesome-icon icon="fa-solid fa-user-plus" />
        &nbsp; Novo registro
      </a>
    </div>
    <div style="float:right; width: 30%; margin-left: 10px;">
      <input @keyup="filtrarPorPesquisa" v-model="pesquisa" class="form-control" type="text" placeholder="Pesquisa por nome...">
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nome</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"> Ações</th>
            </tr>
          </thead>
          <tbody>
            <!-- Fazer v-for no tr -->
            <tr v-for="(perfil, index) in laravelData.data" :key="index">
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ perfil.name }}</h6>
                  </div>
                </div>
              </td>
              <td class="align-middle" style="text-align:center;">
                <div class="mt-2">
                  <a
                    href="javascript:;"
                    class="font-weight-bold text-xs btn btn-default"
                    data-toggle="tooltip"
                    data-original-title="Edit user"
                    @click="openShowModal(perfil._id)"
                    >
                    <font-awesome-icon icon="fa-solid fa-eye" />
                    Visualizar</a
                  >
                  <a
                    v-if="perfil.name != 'admin'"
                    href="javascript:;"
                    class="font-weight-bold text-xs btn btn-info"
                    data-toggle="tooltip"
                    data-original-title="Edit user"
                    @click="openEditModal(perfil._id)"
                    >
                    <font-awesome-icon icon="fa-solid fa-edit" />
                    Editar</a
                  >
                  <a
                    v-if="perfil.name != 'admin'"
                    href="javascript:;"
                    class="font-weight-bold text-xs btn btn-danger"
                    data-toggle="tooltip"
                    data-original-title="Delete"
                    @click="remove(perfil._id, index)"
                    >
                    <font-awesome-icon icon="fa-solid fa-trash-alt" />
                    Excluir</a
                  >
                </div>
              </td>
            </tr>
            <!-- end v-for -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div style="float:right">
    <Pagination :key="page"  v-if="laravelData" :laravelData="laravelData" @changepage="getAll" />
  </div>
</template>

<script>
  // import SoftAvatar from "@/components/SoftAvatar.vue";
  import PerfisDataService from "../../services/PerfisDataService";
  import {openModal} from "jenesius-vue-modal";
  import PerfisCreate from "./PerfisCreate.vue";
  import PerfisEdit from "./PerfisEdit.vue";
  import { useToast } from "vue-toastification";
  import PerfisShow from "./PerfisShow.vue";
  import Pagination from "./Pagination.vue";

  // import pagination from 'laravel-vue-pagination';
  // import { ref } from 'vue';
  
  export default {
    setup() {
      const toast = useToast();
      return { toast };
    },
    name: "perfis-table",
    data() {
      return {
        laravelData: {
            type:Object,
            default:null
        },
        page: 0,
        pesquisa: "",
      };
    },
    methods: {
        getAll(page) {
          let params = {page: page};
          if(this.pesquisa != ""){
            console.log("tem pesquisa")
            params = {page: page, name: this.pesquisa};
          }
          console.log(params);
          PerfisDataService.getAll(params)
          .then(response => {
            this.laravelData = response.data.profiles;
            this.page = page;
          })
          .catch(e => {
            console.log(e);
          });
        },
        filtrarPorPesquisa(){
          this.getAll();
        },  
        async openCreateModal() {
          const modal = await openModal(PerfisCreate, {});
  
          modal.on('create', () => {
              this.toast.success("Perfil criado com sucesso!", {
                timeout: 6000
              });
              this.getAll(this.params);
          })
        },
        async openEditModal(_id) {
          // fazer o fetch dos dados aqui e passar via props
          const modal = await openModal(PerfisEdit, {idperfil : _id});
          modal.on('update', () => {
              this.toast.success("Perfil editado com sucesso!", {
                timeout: 6000
              });
              this.getAll(this.params);
          })

        },
        openShowModal(_id) {
          // fazer o fetch dos dados aqui e passar via props
          console.log(_id);
          openModal(PerfisShow, {idperfil: _id});
        },
        remove(_id, index) {
        // Use sweetalert2
        this.$swal({
          title: 'Você tem certeza?',
          text: "Deseja mesmo excluir este perfil?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim, desejo!',
          cancelButtonText: 'Não, cancelar!',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            this.toast.success("Perfil excluído com sucesso!", {
              timeout: 6000
            });
            PerfisDataService.delete(_id);
            this.laravelData.data.splice(index, 1);
            this.$swal(
              'Perfil excluído!',
              'O perfil foi excluído com sucesso.',
              'success'
            )
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === this.$swal.DismissReason.cancel
          ) {
            this.$swal(
              'Ação cancelada',
              'A exclusão foi cancelada.',
              'error'
            )
          }
        })
      },
      //   confirmStatusChange(id, status, index) {
      //     console.log(index);
      //   // Use sweetalert2
      //   this.$swal({
      //     title: 'Você tem certeza?',
      //     text: "Deseja mesmo alterar o status desta unidade de lotação?",
      //     icon: 'warning',
      //     showCancelButton: true,
      //     confirmButtonText: 'Sim, desejo!',
      //     cancelButtonText: 'Não, cancelar!',
      //     reverseButtons: true
      //   }).then((result) => {
      //     if (result.isConfirmed) {
      //       this.toast.success("Status alterado com sucesso!", {
      //         timeout: 6000
      //       });
      //       if(status){
      //         this.deactivate(id);
      //         this.laravelData.data[index].isActive = false;
      //       } else {
      //         this.activate(id);
      //         this.laravelData.data[index].isActive = true;
      //       }
      //       this.$swal(
      //         'Status alterado!',
      //         'O status da unidade de lotação foi alterado com sucesso.',
      //         'success'
      //       )
      //     } else if (
      //       result.dismiss === this.$swal.DismissReason.cancel
      //     ) {
      //       this.$swal(
      //         'Ação cancelada',
      //         'A alteração de status foi cancelada.',
      //         'error'
      //       )
      //     }
      //   })
      // },
    },
    mounted() {
      this.getAll(1);
    },
    components: {
      // SoftAvatar,
      Pagination
    },
  };
  </script>
