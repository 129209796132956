<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <h6>Gerar relatório de licença</h6>
    </div>
    <div style="text-align:right; margin-right: 1%; margin-top: -3%;">
    </div>
    <br>
    <br>
    <div style="float:right; margin-left: 10px; margin-right: 10px;"  class="row">
      <div class="col-md-3">
        <label for="">Modelo</label>
        <select v-model="licenca_selecionada" @change="getLicenceTypes" name="licenca" id="licenca" class="form-select form-control">
          <option selected disabled value="">Selecione...</option>
          <option v-for="(licenca, index) in licencas" :key="index" :value='licenca._id'>{{ licenca.name  }}</option>
        </select>
      </div>
      <div class="col-md-3">
        <label for="">Tipo</label>
        <select v-model="tipo_selecionado" @change="handleTypeSelection" name="tipo" id="tipo" class="form-select form-control">
          <option selected disabled value="">Selecione...</option>
          <option v-for="(tipo, index) in tipos" :key="index" :value='tipo._id'>{{ tipo.name  }}</option>

        </select>
      </div>
      <div class="col-md-3">
        <label for="">Subtipo</label>
        <select v-model="subtipo_selecionado" @change="handleSubTypeSelection" name="subtipo" id="subtipo" class="form-select form-control">
          <option selected disabled value="">Selecione...</option>
          <option v-for="(subtipo, index) in subtipos" :key="index" :value='subtipo._id'>{{ subtipo.name  }}</option>
        </select>
      </div>
      <div class="col-md-3">
        <label for="">Finalidade</label>
        <select v-model="finalidade_selecionada" @change="handleFinalitySelection" name="finalidade" id="finalidade" class="form-select form-control">
          <option selected disabled value="">Selecione...</option>
          <option v-for="(finalidade, index) in finalidades" :key="index" :value='finalidade._id'>{{ finalidade.name  }}</option>
        </select>
      </div>
    </div>
    <hr>
    <div class="col-md-4" style="margin-left:22px;">
      <a href="#"
        @click="openFieldsModal"
        class="btn btn-info"
      >
        <font-awesome-icon icon="fa-solid fa-plus-square" />
        &nbsp; Adicionar campos
      </a>
      <a href="#"
        @click="gerarCsv"
        style="margin-left:4px;"
        class="btn btn-success"
      >
        <font-awesome-icon icon="fa-solid fa-file-excel" />
        &nbsp; Gerar CSV
      </a>
      <a href="#"
        @click="gerarPdf"
        style="margin-left:4px;"
        class="btn btn-success"
      >
        <font-awesome-icon icon="fa-solid fa-file-pdf" />
        &nbsp; Gerar PDF
      </a>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-left text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nome</th>
              <!-- <th class="text-left text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Data</th> -->
              <th class="text-left text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" v-for="(campo, index) in reports[0]?.fields" :key="index">{{campo.key}}</th>
            </tr>
          </thead>
          <tbody>
              <tr v-for="(report, index) in reports" :key="index">
                <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 style="text-align:right" class="mb-0 text-sm">{{ report.name }}</h6>
                    </div>
                  </div>
                </td>
                <!-- <td>
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 style="text-align:right" class="mb-0 text-sm">{{ report.dueDate }}</h6>
                    </div>
                  </div>
                </td> -->
                <td v-for="(field, index) in report.fields" :key="index">
                  <div class="d-flex px-2 py-1">
                    <div class="d-flex flex-column justify-content-center">
                      <h6 style="text-align:right" class="mb-0 text-sm">{{ field.value }}</h6>
                    </div>
                  </div>
                </td>
              </tr>
            <!-- aqui vai entrar v-for do gerar -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div style="float:right">
    <Pagination :key="page"  v-if="laravelData" :laravelData="laravelData" @changepage="getAll" />
  </div>
</template>

<script>
// import SoftAvatar from "@/components/SoftAvatar.vue";
import RelatoriosDataService from "../../services/RelatoriosDataService";
import LicencasDataService from "../../services/LicencasDataService";
import TiposDataService from "../../services/TiposDataService";
import SubtiposDataService from "../../services/SubtiposDataService";
import FinalidadesDataService from "../../services/FinalidadesDataService";
import { useToast } from "vue-toastification";
import Pagination from "./Pagination.vue";
import { openModal } from "jenesius-vue-modal";
import RelatoriosCampos from "./RelatoriosCampos.vue";


// import pagination from 'laravel-vue-pagination';
// import { ref } from 'vue';

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: "relatorios-table",
  data() {
    return {
      laravelData: {
          type:Object,
          default:null
      },
      paramFieldsAux: "",
      reports: "",
      campos_selecionados: "",
      licencas: [],
      licenca_selecionada: "",
      tipos: [],
      tipo_selecionado: "",
      tipo_fields: "",
      subtipos: [],
      subtipo_selecionado: "",
      subtipo_fields: "",
      finalidades: [],
      finalidade_selecionada: "",
      finalidade_fields: "",
      // pesquisaDataExpiracao: "",
    };
  },
  methods: {
    async openFieldsModal() {
      if(this.licenca_selecionada    != "" &&
         this.tipo_selecionado       != "" &&
         this.subtipo_selecionado    != "" &&
         this.finalidade_selecionada != ""){
          
        const modal = await openModal(RelatoriosCampos, {tipo_fields: this.tipo_fields, 
                                                      subtipo_fields: this.subtipo_fields, 
                                                      finalidade_fields: this.finalidade_fields
                                                    });
        modal.on('update-fields', (camposRetorno) => {
            console.log(camposRetorno);
            this.generateReportList(camposRetorno);
            this.toast.success("Campos do relatório alterados com sucesso!", {
              timeout: 6000
            });
            // this.getAll(this.params);
        })
      } else {
        this.toast.info("Para adicionar campos é preciso selecionar Modelo, Tipo, Sub-tipo e Finalidade!", {
          timeout: 6000
        });
      }
    },
    generateReportList(paramFields) {
      // Pra utilizar no gerarCSV e PDF
      this.paramFieldsAux = paramFields;

      RelatoriosDataService.generate({
        licenseId: this.licenca_selecionada,
        typeId: this.tipo_selecionado,
        subTypeId: this.subtipo_selecionado,
        finalityId: this.finalidade_selecionada,
        fields: paramFields
      }).then((response) => {
            this.reports = response.data.reports;
            console.log(response);
        }).catch(e => {
            console.log(e)
        });
    },
    async gerarCsv() {
      if(this.paramFieldsAux == "") {
        this.toast.info("Para gerar o relatório é preciso ter adicionado campos!", {
          timeout: 6000
        });
      } else {
        RelatoriosDataService.csv({
          licenseId: this.licenca_selecionada,
          typeId: this.tipo_selecionado,
          subTypeId: this.subtipo_selecionado,
          finalityId: this.finalidade_selecionada,
          fields: this.paramFieldsAux
        }).then((response) => {
            this.toast.success("O download do CSV iniciará em instantes!", {
              timeout: 6000
            });
            window.location.href = response.data;
        }).catch(e => {
            console.log(e)
        });
      }
    },
    async gerarPdf() {
      if(this.paramFieldsAux == "") {
        this.toast.info("Para gerar o relatório é preciso ter adicionado campos!", {
          timeout: 6000
        });
      } else {
        RelatoriosDataService.pdf({
          licenseId: this.licenca_selecionada,
          typeId: this.tipo_selecionado,
          subTypeId: this.subtipo_selecionado,
          finalityId: this.finalidade_selecionada,
          fields: this.paramFieldsAux
        }).then((response) => {
            this.toast.success("O download do PDF iniciará em instantes!", {
              timeout: 6000
            });
            window.location.href = response.data;
        }).catch(e => {
            console.log(e)
        });
      }
    },
    getAllLicenses() {
          LicencasDataService.getAll({"active": true})
          .then(response => {
              console.log(response);
              this.licencas = response.data.license.data;
          })
          .catch(e => {
              console.log(e);
          });
      },
      getLicenceTypes() {
          this.tipo_selecionado = "";       
          this.subtipo_selecionado = "";                        
          this.finalidade_selecionada = "";                    

          LicencasDataService.show(this.licenca_selecionada)
          .then(response => {
              this.tipos = response.data.types;
          })
          .catch(e => {
              console.log(e);
          });
      },
      handleTypeSelection() {
          this.subtipo_selecionado = "";                        
          this.finalidade_selecionada = "";   

          TiposDataService.show(this.tipo_selecionado, {fieldRequired: true})
          .then((response) => {
              console.log(response);
              this.tipo_fields = response.data.fields;
              this.subtipos = response.data.subTypes;
          }).catch(e => {
              console.log(e)
          });
      },  
      handleSubTypeSelection() {
          this.finalidade_selecionada = "";   
                  
          SubtiposDataService.show(this.subtipo_selecionado, {fieldRequired: true})
          .then((response) => {
              this.subtipo_fields = response.data.fields;
              this.finalidades = response.data.finalities;
          }).catch(e => {
              console.log(e)
          });
      },
      handleFinalitySelection() {
          FinalidadesDataService.show(this.finalidade_selecionada, {fieldRequired: true})
          .then((response) => {
              this.finalidade_fields = response.data.fields;
          }).catch(e => {
              console.log(e)
          });
      },
    filtrarPorPesquisa(){
      this.getAll();
    },  
  },
  mounted() {
    this.getAllLicenses(this.params);
  },
  components: {
    Pagination
  },
};
</script>
