import http from "../http-common";

// Route::get('user/index', [UserController::class, 'index'])->middleware(['permission:user-index']);
// Route::post('user/store', [UserController::class, 'store'])->middleware(['permission:user-store']);
// Route::get('user/show/{id}', [UserController::class, 'show'])->middleware(['permission:user-show']);
// Route::put('user/update/{id}', [UserController::class, 'update'])->middleware(['permission:user-update']);
// Route::put('user/deactivate/{id}', [UserController::class, 'deactivate'])->middleware(['permission:user-deactivate']);
// Route::put('user/activate/{id}', [UserController::class, 'activate'])->middleware(['permission:user-activate']);

class UsuariosDataService {
  getAll(requestParams) {
    return http.get("/user/index", {params : requestParams});
  }
  create(data) {
    return http.post("/user/store", data);
  }
  show(id) {
    return http.get(`/user/show/${id}`);
  }
  update(id, data) {
    return http.put(`/user/update/${id}`, data);
  }
  deactivate(id) {
    return http.put(`/user/deactivate/${id}`);
  }
  activate(id) {
    return http.put(`/user/activate/${id}`);
  }

}
export default new UsuariosDataService();