<template>
    <div class="crud-modal">
        <div class="card card-plain">

                <div class="pb-0 card-header text-start"> 
                  <h3 class="font-weight-bolder">
                    Novo usuário
                  </h3>
                  <p class="mb-0">Insira os dados abaixo.</p>
                </div>
                <div class="card-body">
                  <form role="form" class="text-start">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Nome <span style="color:red">*</span></label>
                            <soft-input
                            id="Nome"
                            type="text"
                            placeholder="Insira o nome..."
                            name="Nome"
                            v-model:value="usuario.name"
                            />
                        </div>
                        <div class="col-md-3">
                            <label>CPF <span style="color:red">*</span></label>
                            <soft-input
                            id="cpf"
                            type="text"
                            placeholder="Ex: 000.000.000-00"
                            name="cpf"
                            :mask="'###.###.###-##'"
                            v-model:value="usuario.cpf"
                            />
                        </div>
                        <div class="col-md-3">
                            <label for="">Unidade de lotação <span style="color:red">*</span></label>
                            <select v-model="usuario.und_lotacao" name="und_lotacao" id="und_lotacao" class="form-select form-control">
                                <!-- V-for unds_lotacao -->
                                <option disabled value="">Selecione...</option>
                                <option v-for="(unidade, index) in unds_lotacao" :key="index" :value='unidade._id'>{{ unidade.name  }}</option>
                                <!-- V-for unds_lotacao -->
                            </select>
                        </div>  
                    </div>
                    <div class="row">
                        <div class="col-md-7">
                            <label>Email</label>
                            <soft-input
                            id="email"
                            type="email"
                            placeholder="Ex: email@provedor.com"
                            name="email"
                            v-model:value="usuario.email"
                            />
                        </div>
                        <div class="col-md-5">
                            <label>Senha <span style="color:red">*</span></label>
                            <soft-input
                            id="password"
                            type="password"
                            placeholder="Senha"
                            name="password"
                            v-model:value="usuario.password"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label>Telefone</label>
                            <soft-input
                            type="text"
                            placeholder="Ex: (96) 99999-9999"
                            name="phone"
                            :mask="'(##) #####-####'"
                            v-model:value="usuario.phone"
                            />
                        </div>
                        <div class="col-md-6">
                            <label for="">Perfil <span style="color:red">*</span></label>
                            <select v-model="usuario.profile_type" name="perfil" id="perfil" class="form-select form-control">
                                <!-- V-for perfis -->
                                <option disabled value="">Selecione...</option>
                                <option v-for="(perfil, index) in perfis" :key="index" :value="perfil._id">{{ perfil.name }}</option>
                                <!-- V-for perfis -->
                            </select>
                        </div>                            
                    </div>
                    </form>
                    <hr>
                    <div style="text-align: right;">
                        <a @click="create" href="#" class="btn btn-success">
                            Salvar
                        </a>
                        <a href="#" class="btn btn-default" @click="close">
                            Cancelar
                        </a>
                    </div>
                </div> 
              </div>
    </div>
</template>
<style>
    .modal-container {
        z-index: 93949349394349;
    }
    .modal-item {
        width: 50%;
    }
</style>
<script>
    import SoftInput from "@/components/SoftInput.vue";
    import {closeModal} from "jenesius-vue-modal"
    import UnidadesDeLotacaoDataService from "../../services/UnidadesDeLotacaoDataService";
    import PerfisDataService from "../../services/PerfisDataService";
    import UsuariosDataService from "../../services/UsuariosDataService";

    export default {
        data() {
            return {
                params: {"page" : 1},
                unds_lotacao: [],
                perfis: [],
                usuario: {
                    name: "",
                    cpf: "",
                    email: "",
                    phone: "",
                    profile_type: "",
                    und_lotacao: "",
                    password: ""
                }
            }
        },
        props: {
            title: String
        },
        components: {
            SoftInput,
        },
        methods: {
            close() {
                closeModal();
            },
            getAllSubselects() {
                UnidadesDeLotacaoDataService.getAll({"active": true})
                .then(response => {
                    this.unds_lotacao = response.data.undsLotacao.data;
                })
                .catch(e => {
                    console.log(e);
                });

                PerfisDataService.getAll()
                .then(response => {
                    this.perfis = response.data.profiles.data;
                })
                .catch(e => {
                    console.log(e);
                });
            },
            create() {
                console.log(this.usuario)
                UsuariosDataService.create(this.usuario)
                .then(() => {
                    this.$emit("create");
                    this.close();
                })
                .catch(e => {
                    console.log(e);
                });
            },
        },
        mounted() {
            this.getAllSubselects();
        }
    }
</script>