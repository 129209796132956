import http from "../http-common";


  //  //licenseCRUD
  //  Route::post('license/store', [LicenseController::class, 'store'])->middleware(['permission:license-store']);
  //  Route::get('license/index', [LicenseController::class, 'index'])->middleware(['permission:license-index']);
  //  Route::get('license/show/{id}', [LicenseController::class, 'show'])->middleware(['permission:license-show']);
  //  Route::put('license/update/{id}', [LicenseController::class, 'update'])->middleware(['permission:license-update']);
  //  Route::put('license/deactivate/{id}', [LicenseController::class, 'deactivate'])->middleware(['permission:license-deactivate']);
  //  Route::put('license/activate/{id}', [LicenseController::class, 'activate'])->middleware(['permission:license-activate']);


  //  //license_typesCRUD
  //  Route::get('license/type/index', [LicenseTypeController::class, 'index'])->middleware(['permission:license_type-index']);
  //  Route::post('license/type/store', [LicenseTypeController::class, 'store'])->middleware(['permission:license_type-store']);
  //  Route::get('license/type/show/{id}', [LicenseTypeController::class, 'show'])->middleware(['permission:license_type-show']);
  //  Route::delete('license/type/delete/{id}', [LicenseTypeController::class, 'delete'])->middleware(['permission:license_type-delete']);
  //  Route::put('license/type/update/{id}', [LicenseTypeController::class, 'update'])->middleware(['permission:license_type-update']);

  //  //field
  //  Route::put('field/update/{id}', [FieldController::class, 'update'])->middleware(['permission:field-update']);
  //  Route::get('field/show/{id}', [FieldController::class, 'show'])->middleware(['permission:field-show']);

  //  //license_subTypesCRUD
  //  Route::get('license/sub_type/index', [LicenseSubTypeController::class, 'index'])->middleware(['permission:license_sub_type-index']);
  //  Route::post('license/sub_type/store', [LicenseSubTypeController::class, 'store'])->middleware(['permission:license_sub_type-store']);
  //  Route::get('license/sub_type/show/{id}', [LicenseSubTypeController::class, 'show'])->middleware(['permission:license_sub_type-show']);
  //  Route::delete('license/sub_type/delete/{id}', [LicenseSubTypeController::class, 'delete'])->middleware(['permission:license_sub_type-delete']);
  //  Route::put('license/sub_type/update/{id}', [LicenseSubTypeController::class, 'update'])->middleware(['permission:license_sub_type-update']);

  //  //license_finalityCRUD
  //  Route::get('license/finality/index', [FinalityController::class, 'index'])->middleware(['permission:license_finality-index']);
  //  Route::post('license/finality/store', [FinalityController::class, 'store'])->middleware(['permission:license_finality-store']);
  //  Route::get('license/finality/show/{id}', [FinalityController::class, 'show'])->middleware(['permission:license_finality-show']);
  //  Route::delete('license/finality/delete/{id}', [FinalityController::class, 'delete'])->middleware(['permission:license_finality-delete']);
  //  Route::put('license/finality/update/{id}', [FinalityController::class, 'update'])->middleware(['permission:license_finality-update']);

class TiposDataService {
  getAll(requestParams) {
    return http.get("/license/type/index", {params : requestParams});
  }
  create(data) {
    return http.post("/license/type/store", data);
  }
  show(id, requestparams) {
    return http.get(`/license/type/show/${id}`, {params : requestparams});
  }
  delete(id) {
    return http.delete(`/license/type/delete/${id}`);
  }
  update(id, data) {
    return http.put(`/license/type/update/${id}`, data);
  }
  deleteField(id, idField) {
    return http.delete(`license/type/${id}/field/${idField}`);
  }
}
export default new TiposDataService();