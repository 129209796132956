<template>
    <div class="crud-modal">
        <div class="card card-plain">

                <div class="pb-0 card-header text-start"> 
                  <h3 class="font-weight-bolder">
                    Detalhes da licença
                  </h3>
                  <!-- <p class="mb-0">Insira os dados abaixo.</p> -->
                </div>
                <div class="card-body">
                  <form role="form" class="text-start">
                    <div class="row">
                        <div class="col-md-3">
                            <label>Nome</label>
                            <p>{{ licencaform.name }}</p>
                        </div>
                        <div class="col-md-3">
                            <label for="">Ativa</label>
                            <p v-if="licencaform.status == 'active'">Sim</p>
                            <p v-else-if="licencaform.status == 'inactive'">Não</p>
                        </div>
                    </div>
                    <hr>
                    <h4>Dados de tipo</h4>
                    <div class="row">
                        <div v-for="(field, index) in licencaform.formData?.type?.fields" :key="index" class="col-md-3">
                            <b>{{ field.key }}: </b><span v-if="field.fieldType == 'date'">{{ field.value.split("-").reverse().join("/") }}</span> <span v-else>{{ field.value }}</span>
                        </div>
                    </div>
                    <hr>
                    <h4>Dados de sub-tipo</h4>
                    <div class="row">
                        <div v-for="(field, index) in licencaform.formData?.subType?.fields" :key="index" class="col-md-3">
                            <b>{{ field.key }}: </b><span v-if="field.fieldType == 'date'">{{ field.value.split("-").reverse().join("/") }}</span> <span v-else>{{ field.value }}</span>
                        </div>
                    </div>
                    <hr>
                    <h4>Dados de finalidade</h4>
                    <div class="row">
                        <div v-for="(field, index) in licencaform.formData?.finality?.fields" :key="index" class="col-md-3">
                            <b>{{ field.key }}: </b><span v-if="field.fieldType == 'date'">{{ field.value.split("-").reverse().join("/") }}</span> <span v-else>{{ field.value }}</span>
                        </div>
                    </div>
                    </form>
                    <hr>
                    <div style="text-align: right;">
                        <a v-if="mostrarBotaoAprovar" @click="approveFormRequest" href="#" class="btn btn-success">
                            Aprovar solicitação
                        </a>
                        <a href="#" class="btn btn-default" @click="close">
                            Fechar
                        </a>
                    </div>
                </div> 
              </div>
    </div>
</template>
<style>
    .modal-container {
        z-index: 93949349394349;
    }
    .modal-item {
        width: 50%;
    }
</style>
<script>
    import {closeModal} from "jenesius-vue-modal"
    import LicencasFormsDataService from "../../services/LicencasFormsDataService";

    export default {
        data() {
            return {
                licencaform: "",
            }
        },
        props: {
            idformlicenca: String,
            mostrarBotaoAprovar: Boolean,
        },
        components: {
        },
        methods: {
            fetchLicencaForm() {
                LicencasFormsDataService.show(this.idformlicenca)
                .then(response => {
                    console.log(response);
                    this.licencaform = response.data.licenseForm;
                })
                .catch(e => {
                    console.log(e);
                });
            },
            approveFormRequest() {
                this.$emit("approved");
                this.close();
            },
            close() {
                closeModal();
            }
        },
        mounted() {
            this.fetchLicencaForm();
        }
    }
</script>