<template>
    <div class="crud-modal">
        <div class="card card-plain">
            <div class="pb-0 card-header text-start"> 
                <h3 class="font-weight-bolder">
                Detalhes da unidade de lotação
                </h3>
                <p class="mb-0"></p>
            </div>
            <div class="card-body">
                <form role="form" class="text-start">
                <div class="row">
                    <div class="col-md-6">
                        <label>Nome</label>
                        <p>{{ unidade.name }}</p>
                    </div>
                    <div class="col-md-6">
                        <label for="">Status</label>
                        <p v-if="unidade.isActive">Habilitada</p>
                        <p v-else>Desabilitada</p>
                    </div>
                </div>
                <div style="text-align: right;">
                    <a href="#" class="btn btn-default" @click="close">
                        Fechar
                    </a>
                </div>
                </form>
            </div> 
        </div>
    </div>
</template>
<style>
    .modal-container {
        z-index: 93949349394349;
    }
    .modal-item {
        width: 50%;
    }
</style>
<script>
    import {closeModal} from "jenesius-vue-modal"
    import UnidadesDeLotacaoDataService from "../../services/UnidadesDeLotacaoDataService";

    export default {
        data() {
            return {
                unidade: "",
            }
        },
        props: {
            idunidade: String
        },
        methods: {
            fetchUnidade() {
                UnidadesDeLotacaoDataService.show(this.idunidade)
                .then(response => {
                    this.unidade = response.data.undLotacao;
                })
                .catch(e => {
                    console.log(e);
                });
            },
            close() {
                closeModal();
            }
        },
        mounted() {
            this.fetchUnidade();
        }
    }
</script>