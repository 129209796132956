<template>
    <div class="crud-modal">
        <div class="card card-plain">

                <div class="pb-0 card-header text-start"> 
                  <h3 class="font-weight-bolder">
                    Novo modelo de licença
                  </h3>
                  <p class="mb-0">Insira os dados abaixo.</p>
                </div>
                <div class="card-body">
                  <form role="form" class="text-start">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Nome</label>
                            <soft-input
                            id="Nome"
                            type="text"
                            placeholder="Insira o nome..."
                            name="Nome"
                            v-model:value="licenca.name"
                            />
                        </div>
                        <div class="col-md-6">
                            <!-- <label>Senha</label>
                            <soft-input
                            id="password"
                            type="password"
                            placeholder="Senha"
                            name="password"
                            /> -->
                            <!-- <label for="">Status</label> -->
                            <!-- <select v-model="unidade.isActive" name="status" id="status" class="form-select form-control"> -->
                                <!-- <option selected value=true>Habilitada</option> -->
                                <!-- <option value=false>Desabilitada</option> -->
                            <!-- </select> -->
                        </div>
                    </div>
                    <hr>
                    <div style="text-align: right;">
                        <a href="#" class="btn btn-success"
                        @click="create">
                            Salvar
                        </a>
                        <a href="#" class="btn btn-default" @click="close">
                            Cancelar
                        </a>
                    </div>
                  </form>
                </div> 
              </div>
    </div>
</template>
<style>
    .modal-container {
        z-index: 93949349394349;
    }
    .modal-item {
        width: 50%;
    }
</style>
<script>
    import SoftInput from "@/components/SoftInput.vue";
    import {closeModal} from "jenesius-vue-modal"
    import LicencasDataService from "../../services/LicencasDataService";

    export default {
        data() {
            return {
               licenca:  {
                "name" : "",
                // "isActive" : true,
               } 
            }
        },
        props: {
            title: String
        },
        components: {
            SoftInput,
        },
        methods: {
            close() {
                closeModal();
            },
            create() {
                LicencasDataService.create(this.licenca)
                .then(() => {
                    this.$emit("create");
                    this.close();
                })
                .catch(e => {
                    console.log(e);
                });
            },
        }
    }
</script>