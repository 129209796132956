<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <h6>Lista de unidades de lotação</h6>
    </div>
    <div style="text-align:right; margin-right: 1%; margin-top: -3%;">
      <a href="#"
        @click="openCreateModal"
        class="btn btn-success"
      >
        <font-awesome-icon icon="fa-solid fa-plus-square" />
        &nbsp; Novo registro
      </a>
    </div>
    <div style="float:right; width: 30%; margin-left: 10px;">
      <input @keyup="filtrarPorPesquisa" v-model="pesquisa" class="form-control" type="text" placeholder="Pesquisa por nome...">
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nome</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"> Ações</th>
            </tr>
          </thead>
          <tbody>
            <!-- Fazer v-for no tr -->
            <tr v-for="(unidade, index) in laravelData.data" :key="index">
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ unidade.name }}</h6>
                  </div>
                </div>
              </td>
              <td v-if="unidade.isActive" class="align-middle text-center text-sm">
                <soft-badge color="success" variant="gradient" size="sm"
                  >Habilitada</soft-badge
                >
              </td>
              <td v-else class="align-middle text-center text-sm">
                <soft-badge color="danger" variant="gradient" size="sm"
                  >Desabilitada</soft-badge
                >
              </td>
              
              <td class="align-middle" style="text-align:center;">
                <div class="mt-2">
                  <a
                    href="javascript:;"
                    class="font-weight-bold text-xs btn btn-default"
                    data-toggle="tooltip"
                    data-original-title="Edit user"
                    @click="openShowModal(unidade._id)"
                    >
                    <font-awesome-icon icon="fa-solid fa-eye" />
                    Visualizar</a
                  >
                  <a
                    href="javascript:;"
                    class="font-weight-bold text-xs btn btn-info"
                    data-toggle="tooltip"
                    data-original-title="Edit user"
                    @click="openEditModal(unidade._id)"
                    >
                    <font-awesome-icon icon="fa-solid fa-edit" />
                    Editar</a
                  >
                  <a
                    href="javascript:;"
                    class="font-weight-bold text-xs btn btn-warning"
                    data-toggle="tooltip"
                    data-original-title="Edit user"
                    @click="confirmStatusChange(unidade._id, unidade.isActive, index)"
                    >
                    <font-awesome-icon icon="fa-solid fa-sync" />
                    Alterar status</a
                  >
                </div>
              </td>
            </tr>
            <!-- end v-for -->
            <!-- <pagination v-if="laravelData != undefined" :data="laravelData" @pagination-change-page="getAll" /> -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div style="float:right">
    <Pagination :key="page"  v-if="laravelData" :laravelData="laravelData" @changepage="getAll" />
  </div>
</template>

<script>
// import SoftAvatar from "@/components/SoftAvatar.vue";
import UnidadesDeLotacaoDataService from "../../services/UnidadesDeLotacaoDataService";
import SoftBadge from "@/components/SoftBadge.vue";
import {openModal} from "jenesius-vue-modal";
import UnidadesDeLotacaoCreate from "./UnidadesDeLotacaoCreate.vue";
import UnidadesDeLotacaoEdit from "./UnidadesDeLotacaoEdit.vue";
import UnidadesDeLotacaoShow from "./UnidadesDeLotacaoShow.vue";
import { useToast } from "vue-toastification";
import Pagination from "./Pagination.vue";

// import pagination from 'laravel-vue-pagination';
// import { ref } from 'vue';

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: "unidades-de-lotacao-table",
  data() {
    return {
      laravelData: {
          type:Object,
          default:null
      },
      page: 0,
      pesquisa: "",
    };
  },
  methods: {
      getAll(page) {
        let params = {page: page};
        if(this.pesquisa != ""){
          params = {page: page, name: this.pesquisa};
        }
         UnidadesDeLotacaoDataService.getAll(params)
        .then(response => {
          // console.log(response);
          this.laravelData = response.data.undsLotacao;
          this.page = page;
          console.log(response.data.undsLotacao);
        })
        .catch(e => {
          console.log(e);
        });
      },
      activate(id) {
        UnidadesDeLotacaoDataService.activate(id);
      },
      deactivate(id) {
        UnidadesDeLotacaoDataService.deactivate(id);
      },
      filtrarPorPesquisa(){
        this.getAll();
      },  
      async openCreateModal() {
        const modal = await openModal(UnidadesDeLotacaoCreate, {});

        modal.on('create', () => {
            this.toast.success("Unidade criada com sucesso!", {
              timeout: 6000
            });
            this.getAll(this.params);
        })
      },
      async openEditModal(_id) {
        // fazer o fetch dos dados aqui e passar via props
        const modal = await openModal(UnidadesDeLotacaoEdit, {idunidade : _id});

        modal.on('update', () => {
            this.toast.success("Unidade editada com sucesso!", {
              timeout: 6000
            });
            this.getAll(this.params);
        })
      },
      openShowModal(_id) {
        // fazer o fetch dos dados aqui e passar via props
        openModal(UnidadesDeLotacaoShow, {idunidade : _id});
      },
      confirmStatusChange(id, status, index) {
        console.log(index);
      // Use sweetalert2
      this.$swal({
        title: 'Você tem certeza?',
        text: "Deseja mesmo alterar o status desta unidade de lotação?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, desejo!',
        cancelButtonText: 'Não, cancelar!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.toast.success("Status alterado com sucesso!", {
            timeout: 6000
          });
          if(status){
            this.deactivate(id);
            this.laravelData.data[index].isActive = false;
          } else {
            this.activate(id);
            this.laravelData.data[index].isActive = true;
          }
          this.$swal(
            'Status alterado!',
            'O status da unidade de lotação foi alterado com sucesso.',
            'success'
          )
        } else if (
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal(
            'Ação cancelada',
            'A alteração de status foi cancelada.',
            'error'
          )
        }
      })
    },
  },
  mounted() {
    this.getAll(this.params);
  },
  components: {
    // SoftAvatar,
    SoftBadge,
    Pagination
  },
};
</script>
