<template>
    <div class="crud-modal">
        <div class="card card-plain">

                <div class="pb-0 card-header text-start"> 
                  <h3 class="font-weight-bolder">
                    Renovar licença
                  </h3>
                  <p class="mb-0">Insira os dados abaixo.</p>
                </div>
                <div class="card-body">
                  <form role="form" class="text-start">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Nova data de validade</label>
                            <soft-input
                            id="data"
                            type="date"
                            name="data"
                            v-model:value="dueDate"
                            />
                        </div>
                        <!-- <div class="col-md-6">
                            <label for="">Status</label>
                            <select name="status" id="status" class="form-select form-control">
                                <option selected value="true">Habilitada</option>
                                <option value="false">Desabilitada</option>
                            </select>
                        </div> -->
                    </div>
                    <hr>
                    <div style="text-align: right;">
                        <a href="#" class="btn btn-success" @click="renew">
                            Salvar
                        </a>
                        <a href="#" class="btn btn-default" @click="close">
                            Cancelar
                        </a>
                    </div>
                  </form>
                </div> 
              </div>
    </div>
</template>
<style>
    .modal-container {
        z-index: 93949349394349;
    }
    .modal-item {
        width: 50%;
    }
</style>
<script>
    import SoftInput from "@/components/SoftInput.vue";
    import {closeModal} from "jenesius-vue-modal"
    import LicencasFormsDataService from "../../services/LicencasFormsDataService";

    export default {
        data() {
            return {
                // licenca: "",
                dueDate: "",
            }
        },
        props: {
            idformlicenca: String
        },
        methods: {
            renew() {
                console.log(this.dueDate);
                LicencasFormsDataService.renew(this.idformlicenca, {"dueDate": this.dueDate})
                .then(response => {
                    console.log(response);
                    if(response.data.validation_error){
                        return;
                    } else {
                        this.$emit("renew");
                        this.close();
                    }  
                })
                .catch(e => {
                    console.log(e);
                });
            },
            // fetchLicenca() {
            //     LicencasFormsDataService.show(this.idformlicenca)
            //     .then(response => {
            //         console.log(response);
            //         this.licenca = response.data.licenseForm;
            //     })
            //     .catch(e => {
            //         console.log(e);
            //     });
            // },
            close() {
                closeModal();
            }
        },
        components: {
            SoftInput,
        },
        mounted() {
            // this.fetchLicenca();
        }
    }
</script>