<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <solicitacoes-table />
      </div>
    </div>
    
  </div>
</template>

<script>
import SolicitacoesTable from "./components/SolicitacoesTable.vue";

export default {
  name: "unidades-de-lotacao",
  components: {
    SolicitacoesTable,
    // ProjectsTable,
  },
};
</script>
