<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <h6>Lista de tipos de licença ({{ licenca.name }})</h6>
    </div>

    <div style="text-align:right; margin-right: 1%; margin-top: -3%;">
      <a href="#"
        class="btn btn-secondary"
        @click="redirectBack"
      >
        <font-awesome-icon icon="fa-solid fa-arrow-left" />
        &nbsp; Voltar
      </a>
      <a href="#"
        class="btn btn-success"
        @click="openCreateModal"
      >
        <font-awesome-icon icon="fa-solid fa-user-plus" />
        &nbsp; Novo registro
      </a>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nome</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"> Ações</th>
            </tr>
          </thead>
          <tbody>
            <div style="text-align:center; height: 300px;" v-if="laravelData == ''">
              <h4 style="margin-top: 200px;">Ainda não existem tipos cadastrados para essa licença.</h4>
            </div>
            <!-- Fazer v-for no tr -->
            <tr v-for="(tipo, index) in laravelData" :key="index">
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ tipo.name }}</h6>
                  </div>
                </div>
              </td>
              <td class="align-middle" style="text-align:center;">
                <div class="mt-2">
                  <a
                    href="javascript:;"
                    class="font-weight-bold text-xs btn btn-secondary"
                    data-toggle="tooltip"
                    data-original-title="Edit user"
                    @click="redirectToSubtipos(tipo._id)"
                    >
                    <font-awesome-icon icon="fa-solid fa-sitemap" />
                    Sub-tipos</a
                  >
                  <a
                    href="javascript:;"
                    class="font-weight-bold text-xs btn btn-default"
                    data-toggle="tooltip"
                    data-original-title="Edit user"
                    @click="openShowModal(tipo._id)"
                    >
                    <font-awesome-icon icon="fa-solid fa-eye" />
                    Visualizar</a
                  >
                  <a
                    href="javascript:;"
                    class="font-weight-bold text-xs btn btn-info"
                    data-toggle="tooltip"
                    data-original-title="Edit user"
                    @click="openEditModal(tipo._id)"
                    >
                    <font-awesome-icon icon="fa-solid fa-edit" />
                    Editar</a
                  >
                  <a
                    href="javascript:;"
                    class="font-weight-bold text-xs btn btn-danger"
                    data-toggle="tooltip"
                    data-original-title="Delete"
                    @click="remove(tipo._id, index)"
                    >
                    <font-awesome-icon icon="fa-solid fa-trash-alt" />
                    Excluir</a
                  >
                </div>
              </td>
            </tr>
            <!-- end v-for -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { openModal } from "jenesius-vue-modal";
import TiposCreate from "./TiposCreate.vue";
import TiposEdit from "./TiposEdit.vue";
import TiposShow from "./TiposShow.vue";
import LicencasDataService from "../../services/LicencasDataService";
import { useToast } from "vue-toastification";
import router from "@/router";
import TiposDataService from "../../services/TiposDataService";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: "licencas-table",
  data() {
    return {
      licenca: "",
      laravelData: "",
      params: {"page" : 1},
      url_id: "",
    };
  },
  methods: {
    getAllChildren() {
         LicencasDataService.show(this.url_id)
        .then(response => {
          console.log(response.data.types);
          this.laravelData = response.data.types;
          this.licenca = response.data.license;
        })
        .catch(e => {
          console.log(e);
        });
      },
      redirectToSubtipos(_id) {
        router.push("/subtiposportipo/" + _id);
      },
      redirectBack() {
        router.push("/modelos");
      },
      async openCreateModal() {
        const modal = await openModal(TiposCreate, {parentid: this.url_id});

        modal.on('create', () => {
            this.toast.success("Tipo criado com sucesso!", {
              timeout: 6000
            });
            this.getAllChildren();
        })
      },
      async openEditModal(_id) {
        const modal = await openModal(TiposEdit, {tipoid: _id});

        modal.on('update', () => {
            this.toast.success("Tipo editado com sucesso!", {
              timeout: 6000
            });
            this.getAllChildren();
        })
      },
      openShowModal(_id) {
        openModal(TiposShow, {tipoid: _id});
      },
      remove(_id, index) {
        // Use sweetalert2
        this.$swal({
          title: 'Você tem certeza?',
          text: "Deseja mesmo excluir este tipo?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim, desejo!',
          cancelButtonText: 'Não, cancelar!',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            this.toast.success("Tipo excluído com sucesso!", {
              timeout: 6000
            });
            TiposDataService.delete(_id);
            this.laravelData.splice(index, 1);
            this.$swal(
              'Tipo excluído!',
              'O tipo foi excluído com sucesso.',
              'success'
            )
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === this.$swal.DismissReason.cancel
          ) {
            this.$swal(
              'Ação cancelada',
              'A exclusão foi cancelada.',
              'error'
            )
          }
        })
      }
  },
  components: {
  },
  mounted (){
    this.url_id = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
    this.getAllChildren();
  }
};
</script>
