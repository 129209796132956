<template>
  <div class="py-4 container-fluid" style="width: 90%;">
    <h4>Acesso externo</h4>
    <small>Consulta de licenças</small>

    <hr>
    <div class="row">
      <div class="col-12">
        <acesso-externo-table />
      </div>
    </div>
    
  </div>
</template>

<script>
import AcessoExternoTable from "./components/AcessoExternoTable";

export default {
  name: "acessoexterno",
  components: {
    AcessoExternoTable,
  },
  mounted() {
    this.$store.state.showSidenav = false;
    this.$store.state.showNavbar = false;
  }
};
</script>
