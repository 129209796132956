<template>
  <ul v-if="laravelData" class="pagination pagination-lg">
    <li v-for="(data, index) in laravelData.links" :key="index" :class="[data.active ? 'active' : '', data.url ? '' : 'disabled']" class="page-item"><a class="page-link" @click="$emit('changepage', data.url.slice(-1) )">{{ parsePageText(data.label) }}</a></li>
  </ul>

</template>
<style>
.page-item.active > *{
  background-color:#82D616 !important;
  border: none;
  cursor: no-drop   ;
}
.page-item.disabled > *{
  background-color: #d1d1d1 !important;
}
.page-item.disabled {
  cursor: no-drop;
}
.page-item > *{
  cursor: pointer;
}
</style>
  <script>
  export default {
    name: "Pagination",
    props: {
      laravelData: {
        type: Object,
      },
    },
    methods: {
      parsePageText(text){
        if(text == "&laquo; Anterior"){
          return "←"
        }
        if(text == "Próximo &raquo;"){
          return "→"
        }
        return text;
      }
    }, 
    mounted () {
        console.log(this.laravelData);
    },

  };
  </script>
  