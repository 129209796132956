<template>
    <div class="crud-modal">
        <div class="card card-plain">

                <div class="pb-0 card-header text-start"> 
                  <h3 class="font-weight-bolder">
                    Detalhes do usuário
                  </h3>
                  <!-- <p class="mb-0">Insira os dados abaixo.</p> -->
                </div>
                <div class="card-body">
                  <form role="form" class="text-start">
                    <div class="row">
                        <div class="col-md-3">
                            <label>Nome</label>
                            <p>{{ usuario.name }}</p>
                        </div>
                        <div class="col-md-3">
                            <label>CPF</label>                            
                            <p>{{ usuario.cpf }}</p>
                        </div>
                        <div class="col-md-3">
                            <label for="">Perfil</label>
                            <p>{{ perfil }}</p>
                        </div> 
                        <div class="col-md-3">
                            <label for="">Unidade de lotação</label>
                            <p>{{ unidade }}</p>
                        </div> 
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <label for="">Status</label>
                            <p v-if="usuario.isActive">Habilitado</p>
                            <p v-else>Desabilitado</p>
                        </div>
                        <div class="col-md-3">
                            <label>Telefone</label>
                            <p>{{ usuario.phone }}</p>
                        </div>  
                        <div class="col-md-6">
                            <label>Email</label>
                            <p>{{ usuario.email }}</p>
                        </div>
                    </div>
                    </form>
                    <hr>
                    <div style="text-align: right;">
                        <a href="#" class="btn btn-default" @click="close">
                            Fechar
                        </a>
                    </div>
                </div> 
              </div>
    </div>
</template>
<style>
    .modal-container {
        z-index: 93949349394349;
    }
    .modal-item {
        width: 50%;
    }
</style>
<script>
    import {closeModal} from "jenesius-vue-modal"
    import PerfisDataService from "../../services/PerfisDataService";
    import UnidadesDeLotacaoDataService from "../../services/UnidadesDeLotacaoDataService";
    import UsuariosDataService from "../../services/UsuariosDataService";

    export default {
        data() {
            return {
                perfil: "",
                unidade: "",
                usuario: "",
            }
        },
        props: {
            idusuario: String
        },
        components: {
        },
        methods: {
            fetchUsuario() {
                UsuariosDataService.show(this.idusuario)
                .then(response => {
                    this.usuario = response.data.user;

                    PerfisDataService.show(this.usuario.profile_type)
                    .then(response => {
                        this.perfil = response.data.profile.name;
                    })

                    UnidadesDeLotacaoDataService.show(this.usuario.und_lotacao)
                    .then(response => {
                        this.unidade = response.data.undLotacao.name;
                    })
                })
                .catch(e => {
                    console.log(e);
                });
            },
            close() {
                closeModal();
            }
        },
        mounted() {
            this.fetchUsuario();
        }
    }
</script>