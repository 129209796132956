import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/Dashboard.vue";
import Usuarios from "@/views/Usuarios.vue";
import UnidadesDeLotacao from "@/views/UnidadesDeLotacao.vue";
import Perfis from "@/views/Perfis.vue";
import Solicitacoes from "@/views/Solicitacoes.vue";
import Licencas from "@/views/Licencas.vue";
import LicencasForms from "@/views/LicencasForms.vue";
import Relatorios from "@/views/Relatorios.vue";
import Tipos from "@/views/Tipos.vue";
import Subtipos from "@/views/Subtipos.vue";
import Finalidades from "@/views/Finalidades.vue";
import AcessoExterno from "@/views/AcessoExterno.vue"
// import Billing from "@/views/Billing.vue";
// import VirtualReality from "@/views/VirtualReality.vue";
import Profile from "@/views/Profile.vue";
import Rtl from "@/views/Rtl.vue";
import SignIn from "@/views/SignIn.vue";
import SignUp from "@/views/SignUp.vue";


const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/sign-in",
  },
  {
    path: "/acesso-externo",
    name: "External",
    component: AcessoExterno,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    component: Usuarios,
  },
  {
    path: "/perfis",
    name: "Perfis",
    component: Perfis,
  },
  {
    path: "/unidade-lotacao",
    name: "Unidades",
    component: UnidadesDeLotacao,
  },
  {
    path: "/modelos",
    name: "Modelos",
    component: Licencas,
  },
  {
    path: "/licencasForms",
    name: "LicencasForms",
    component: LicencasForms,
  },
  {
    path: "/relatorios",
    name: "Relatorios",
    component: Relatorios,
  },
  {
    path: "/solicitacoes",
    name: "Solicitacoes",
    component: Solicitacoes,
  },
  {
    path: "/tiposporlicenca/:id",
    name: "Tipos",
    component: Tipos,
  },
  {
    path: "/subtiposportipo/:id",
    name: "Subtipos",
    component: Subtipos,
  },
  {
    path: "/finalidadesporsubtipo/:id",
    name: "Finalidades",
    component: Finalidades,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/rtl-page",
    name: "Rtl",
    component: Rtl,
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    component: SignUp,
  },
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
