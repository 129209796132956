<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <h6>Lista de usuários</h6>
    </div>

    <div style="text-align:right; margin-right: 1%; margin-top: -3%;">
      <a href="#"
        class="btn btn-success"
        @click="openCreateModal"
      >
        <font-awesome-icon icon="fa-solid fa-user-plus" />
        &nbsp; Novo registro
      </a>
    </div>
    <div style="float:right; width: 30%; margin-left: 10px;">
      <input @keyup="filtrarPorPesquisa" v-model="pesquisa" class="form-control" type="text" placeholder="Pesquisa por nome/cpf...">
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nome</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">CPF</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"> Ações</th>
            </tr>
          </thead>
          <tbody>
            <!-- Fazer v-for no tr -->
            <tr v-for="(usuario, index) in laravelData.data" :key="index">
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ usuario.name }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ usuario.cpf }}</p>
              </td>
              <td v-if="usuario.isActive" class="align-middle text-center text-sm">
                <soft-badge color="success" variant="gradient" size="sm"
                  >Habilitado</soft-badge
                >
              </td>
              <td v-else class="align-middle text-center text-sm">
                <soft-badge color="danger" variant="gradient" size="sm"
                  >Desabilitado</soft-badge
                >
              </td>
              <td class="align-middle" style="text-align:center;">
                <div class="mt-2">
                  <a
                    href="javascript:;"
                    class="font-weight-bold text-xs btn btn-default"
                    data-toggle="tooltip"
                    data-original-title="Edit user"
                    @click="openShowModal(usuario._id)"
                    >
                    <font-awesome-icon icon="fa-solid fa-eye" />
                    Visualizar</a
                  >
                  <a
                    href="javascript:;"
                    class="font-weight-bold text-xs btn btn-info"
                    data-toggle="tooltip"
                    data-original-title="Edit user"
                    @click="openEditModal(usuario._id)"
                    >
                    <font-awesome-icon icon="fa-solid fa-edit" />
                    Editar</a
                  >
                  <a
                    href="javascript:;"
                    class="font-weight-bold text-xs btn btn-warning"
                    data-toggle="tooltip"
                    data-original-title="Edit user"
                    @click="confirmStatusChange(usuario._id, usuario.isActive, index)"
                    >
                    <font-awesome-icon icon="fa-solid fa-sync" />
                    Alterar status</a
                  >
                </div>
              </td>
            </tr>
            <!-- end v-for -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div style="float:right">
    <Pagination :key="page"  v-if="laravelData" :laravelData="laravelData" @changepage="getAll" />
  </div>
</template>

<script>
// import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftBadge from "@/components/SoftBadge.vue";
import { openModal } from "jenesius-vue-modal";
import UsuariosCreate from "./UsuariosCreate.vue";
import UsuariosEdit from "./UsuariosEdit.vue";
import UsuariosShow from "./UsuariosShow.vue";
import UsuariosDataService from "../../services/UsuariosDataService";
import { useToast } from "vue-toastification";
import Pagination from "./Pagination.vue";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: "usuarios-table",
  data() {
    return {
      laravelData: {
          type:Object,
      },
      page: 0,
      pesquisa: "",
    };
  },
  methods: {
      getAll(page) {
        let params = {page: page};
        if(this.pesquisa != ""){
          params = {page: page, name: this.pesquisa};
        }
        console.log(params);
        UsuariosDataService.getAll(params)
        .then(response => {
           console.log(page);
          this.laravelData = response.data.users;
          this.page = page;
          console.log(this.laravelData.data.length)          // console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
      },
      activate(id) {
        UsuariosDataService.activate(id);
      },
      deactivate(id) {
        UsuariosDataService.deactivate(id);
      },
      filtrarPorPesquisa(){
        this.getAll();
      },  
      async openCreateModal() {
        const modal = await openModal(UsuariosCreate, {});

        modal.on('create', () => {
            this.toast.success("Usuário criado com sucesso!", {
              timeout: 6000
            });
            this.getAll(this.params);
        })
      },
      async openEditModal(_id) {
        const modal = await openModal(UsuariosEdit, {idusuario: _id});

        modal.on('update', () => {
            this.toast.success("Usuário editado com sucesso!", {
              timeout: 6000
            });
            this.getAll(this.params);
        })
      },
      openShowModal(_id) {
        openModal(UsuariosShow, {idusuario: _id});
      },
      confirmStatusChange(id, status, index) {
      // Use sweetalert2
      this.$swal({
        title: 'Você tem certeza?',
        text: "Deseja mesmo alterar o status deste usuário?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, desejo!',
        cancelButtonText: 'Não, cancelar!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.toast.success("Status alterado com sucesso!", {
            timeout: 6000
          });
          if(status){
            this.deactivate(id);
            this.laravelData.data[index].isActive = false;
          } else {
            this.activate(id);
            this.laravelData.data[index].isActive = true;
          }
          this.$swal(
            'Status alterado!',
            'O status do usuário foi alterado com sucesso.',
            'success'
          )
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal(
            'Ação cancelada',
            'A alteração de status foi cancelada.',
            'error'
          )
        }
      })
    },
  },
  components: {
    // SoftAvatar,
    SoftBadge,
    Pagination,
  },
  mounted (){
    this.getAll(1);
  }
};
</script>
