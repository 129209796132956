<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <h6>Lista de solicitações</h6>
    </div>
    <div style="text-align:right; margin-right: 1%; margin-top: -3%;">
      <a href="#"
        class="btn btn-default"
        @click="redirectLicencasForms"
      >
        <font-awesome-icon icon="fa-solid fa-arrow-left" />
        &nbsp; Voltar
      </a>
    </div>
    <div style="float:right; width: 30%; margin-left: 10px;">
      <input @keyup="filtrarPorPesquisa" v-model="pesquisa" class="form-control" type="text" placeholder="Pesquisa por nome...">
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nome</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Expirada</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Usuário criador</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"> Ações</th>
            </tr>
          </thead>
          <tbody>
            <div style="text-align:center; height: 300px;" v-if="laravelData.data == ''">
              <h4 style="margin-top: 200px;">Ainda não existem solicitações cadastradas.</h4>
            </div>
            <!-- Fazer v-for no tr -->
            <tr v-for="(licenseform, index) in laravelData.data" :key="index">
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ licenseform.name }}</h6>
                  </div>
                </div>
              </td>
              <td v-if="licenseform.expired == false" class="justify-content-center text-sm">
                <soft-badge color="success" variant="gradient" size="sm"
                  >Não</soft-badge
                >
              </td>
              <td v-else class="justify-content-center text-sm">
                <soft-badge color="danger" variant="gradient" size="sm"
                  >Sim</soft-badge
                >
              </td>
              <td v-if="licenseform.status == 'active'" class="justify-content-center text-sm">
                <soft-badge color="success" variant="gradient" size="sm"
                  >Ativa</soft-badge
                >
              </td>
              <td v-else class="justify-content-center text-sm">
                <soft-badge color="danger" variant="gradient" size="sm"
                  >Inativa</soft-badge
                >
              </td>
              <td class="justify-content-center text-sm">
                <h6 class="mb-0 text-sm">{{ licenseform.user.name }}</h6>
              </td>
              <td class="align-middle" style="text-align:center;">
                <div class="mt-2">
                  <a
                    href="javascript:;"
                    class="font-weight-bold text-xs btn btn-default"
                    data-toggle="tooltip"
                    data-original-title="Edit user"
                    @click="openShowModal(licenseform._id, index)"
                    >
                    <font-awesome-icon icon="fa-solid fa-eye" />
                    Detalhes</a
                  >
                  <!-- <a
                    href="javascript:;"
                    class="font-weight-bold text-xs btn btn-info"
                    data-toggle="tooltip"
                    data-original-title="Edit user"
                    @click="openEditModal(licenseform._id)"
                    >
                    <font-awesome-icon icon="fa-solid fa-edit" />
                    Alterar nome</a
                  > -->
                  <!-- <a
                    href="javascript:;"
                    class="font-weight-bold text-xs btn btn-warning"
                    data-toggle="tooltip"
                    data-original-title="Edit user"
                    @click="confirmStatusChange(licenseform._id, licenseform.status, index)"
                    >
                    <font-awesome-icon icon="fa-solid fa-sync" />
                    Alterar status</a
                  > -->
                  <!-- <a
                    href="javascript:;"
                    class="font-weight-bold text-xs btn btn-success"
                    data-toggle="tooltip"
                    data-original-title="Delete"
                    @click="openRenewModal(licenseform._id)"
                    >
                    <font-awesome-icon icon="fa-solid fa-sync" />
                    Renovar</a
                  > -->
                </div>
              </td>
            </tr>
            <!-- end v-for -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div style="float:right">
    <Pagination :key="page"  v-if="laravelData" :laravelData="laravelData" @changepage="getAll" />
  </div>
</template>

<script>
// import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftBadge from "@/components/SoftBadge.vue";
import { openModal } from "jenesius-vue-modal";
import LicencasFormsCreate from "./LicencasFormsCreate.vue";
import LicencasFormsEdit from "./LicencasFormsEdit.vue";
import LicencasFormsShow from "./LicencasFormsShow.vue";
import LicencasFormsRenew from "./LicencasFormsRenew.vue";
import LicencasFormsDataService from "../../services/LicencasFormsDataService";
import { useToast } from "vue-toastification";
import Pagination from "./Pagination.vue";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: "licenseforms-table",
  data() {
    return {
      laravelData: {
          type:Object,
      },
      page: 0,
      pesquisa: "",
    };
  },
  methods: {
      getAll(page) {
        let params = {page: page};
        if(this.pesquisa != ""){
          params = {page: page, name: this.pesquisa};
        }
        // console.log(params);
        LicencasFormsDataService.getAllRequests(params)
        .then(response => {
           console.log(response);
          this.laravelData = response.data.licenseRequests;
          this.page = page;          // console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
      },
      activate(id) {
        LicencasFormsDataService.activate(id);
      },
      deactivate(id) {
        LicencasFormsDataService.deactivate(id);
      },
      filtrarPorPesquisa(){
        this.getAll();
      },  
      async openCreateModal() {
        const modal = await openModal(LicencasFormsCreate, {});

        modal.on('create', () => {
            this.toast.success("Licença criada com sucesso!", {
              timeout: 6000
            });
            this.getAll(this.params);
        })
      },
      async openEditModal(_id) {
        const modal = await openModal(LicencasFormsEdit, {idformlicenca: _id});

        modal.on('update', () => {
            this.toast.success("Licença editada com sucesso!", {
              timeout: 6000
            });
            this.getAll(this.params);
        })
      },
      async openShowModal(_id, index) {
        const modal = await openModal(LicencasFormsShow, {idformlicenca: _id, mostrarBotaoAprovar: true});

        modal.on('approved', () => {

          this.$swal({
            title: 'Você tem certeza?',
            text: "Deseja mesmo aprovar essa solicitação?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, desejo!',
            cancelButtonText: 'Não, cancelar!',
            reverseButtons: true
          }).then((result) => {
            if (result.isConfirmed) {
              LicencasFormsDataService.acceptRequest(_id)
              .then((response) => {
                console.log(response);
                this.toast.success("Solicitação aprovada com sucesso!", {
                  timeout: 6000
                });
                this.$swal(
                  'Solicitação aprovada!',
                  'Solicitação aprovada com sucesso!.',
                  'success'
                )
                this.laravelData.data.splice(index, 1);
                this.getAll(this.params);
              }).catch(e => {
                  console.log(e)
              });
            } else if (
              /* Read more about handling dismissals below */
              result.dismiss === this.$swal.DismissReason.cancel
            ) {
              this.$swal(
                'Ação cancelada',
                'A solicitação permanece em análise.',
                'error'
              )
            }
          })  
        })
      },
      async openRenewModal(_id) {
        const modal = await openModal(LicencasFormsRenew, {idformlicenca: _id});

        modal.on('renew', () => {
            this.toast.success("Licença renovada com sucesso!", {
              timeout: 6000
            });
            this.getAll(this.params);
        })
      },
      remove(_id, index) {
        // Use sweetalert2
        this.$swal({
          title: 'Você tem certeza?',
          text: "Deseja mesmo excluir esta licença?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim, desejo!',
          cancelButtonText: 'Não, cancelar!',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            this.toast.success("Licença excluída com sucesso!", {
              timeout: 6000
            });
            LicencasFormsDataService.delete(_id);
            this.laravelData.splice(index, 1);
            this.$swal(
              'Licença excluída!',
              'A licença foi excluída com sucesso.',
              'success'
            )
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === this.$swal.DismissReason.cancel
          ) {
            this.$swal(
              'Ação cancelada',
              'A exclusão foi cancelada.',
              'error'
            )
          }
        })
      },
      redirectLicencasForms() {
        this.$router.push("/licencasForms");
      },
      confirmStatusChange(id, status, index) {
      // Use sweetalert2
      this.$swal({
        title: 'Você tem certeza?',
        text: "Deseja mesmo alterar o status desta Licença?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, desejo!',
        cancelButtonText: 'Não, cancelar!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.toast.success("Status alterado com sucesso!", {
            timeout: 6000
          });
          if(status == "active"){
            this.deactivate(id);
            this.laravelData.data[index].status = "inactive";
          } else {
            this.activate(id);
            this.laravelData.data[index].status = "active";
          }
          this.$swal(
            'Status alterado!',
            'O status da Licença foi alterado com sucesso.',
            'success'
          )
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal(
            'Ação cancelada',
            'A alteração de status foi cancelada.',
            'error'
          )
        }
      })
    },
  },
  components: {
    // SoftAvatar,
    SoftBadge,
    Pagination,
  },
  mounted (){
    this.getAll(1);
  }
};
</script>
