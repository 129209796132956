<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-2">
        <label for="">Filtro de modelos</label>
        <select name="" @change="handleLicenseSelect" class="form-group form-select" v-model="licensa_selecionada" id="">
          <option disabled value="">Selecione...</option>
          <option value="">Não filtrar...</option>
          <option v-for="(licenca, index) in licencas" :key=index :value="licenca._id">{{ licenca.name }}</option>
        </select>
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-2">
        <mini-statistics-card
          title="Licenças renovadas"
          :value="String(totalizadores.renewedCount)"
          :percentage="{
            value: '+505%',
            color: 'text-success',
          }"
          :icon="{
            component: 'ni ni-building',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-2">
        <mini-statistics-card
          title="Tipos de licença cadastrados"
          :value="String(totalizadores.createdCount)"
          :percentage="{
            value: '+3%',
            color: 'text-success',
          }"
          :icon="{
            component: ' ni ni-books',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>
      <div class="col-xl-3 col-sm-6 mb-xl-0 mb-2">
        <mini-statistics-card
          title="Licenças vencidas"
          :value="String(totalizadores.expiredCount)"
          :percentage="{
            value: '-2%',
            color: 'text-danger',
          }"
          :icon="{
            component: 'ni ni-single-copy-04',
            background: iconBackground,
          }"
          direction-reverse
        />
      </div>
    </div>
    <!-- <div class="row"> -->
      <!-- <div class="col-lg-7 mb-lg-0 mb-4">
        <div class="card">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-lg-6">
                <div class="d-flex flex-column h-100">
                  <p class="mb-1 pt-2 text-bold">Built by developers</p>
                  <h5 class="font-weight-bolder">Soft UI Dashboard</h5>
                  <p class="mb-5">
                    From colors, cards, typography to complex elements, you will
                    find the full documentation.
                  </p>
                  <a
                    class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto"
                    href="javascript:;"
                  >
                    Read More
                    <i
                      class="fas fa-arrow-right text-sm ms-1"
                      aria-hidden="true"
                    ></i>
                  </a>
                </div>
              </div>
              <div class="col-lg-5 ms-auto text-center mt-5 mt-lg-0">
                <div class="bg-gradient-success border-radius-lg h-100">
                  <img
                    src="../assets/img/shapes/waves-white.svg"
                    class="position-absolute h-100 w-50 top-0 d-lg-block d-none"
                    alt="waves"
                  />
                  <div
                    class="position-relative d-flex align-items-center justify-content-center h-100"
                  >
                    <img
                      class="w-100 position-relative z-index-2 pt-4"
                      src="../assets/img/illustrations/rocket-white.png"
                      alt="rocket"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="col-lg-5">
        <div class="card h-100 p-3">
          <div
            class="overflow-hidden position-relative border-radius-lg bg-cover h-100"
            style="
              background-image: url('https://demos.creative-tim.com/soft-ui-dashboard/assets/img/ivancik.jpg');
            "
          >
            <span class="mask bg-gradient-dark"></span>
            <div class="card-body position-relative z-index-1 p-3 h-100">
              <div class="d-flex flex-column h-100">
                <h5 class="text-white font-weight-bolder mb-4 pt-2">
                  Work with the rockets
                </h5>
                <p class="text-white mb-5">
                  Wealth creation is an evolutionarily recent positive-sum game.
                  It is all about who take the opportunity first.
                </p>
                <a
                  class="text-white font-weight-bold ps-1 mb-0 icon-move-left mt-auto"
                  href="javascript:;"
                >
                  Read more
                  <i
                    class="fas fa-arrow-right text-sm ms-1"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    <!-- </div> -->
    <div class="mt-4 row">
      <!-- <div class="mb-4 col-lg-5 mb-lg-0">
        <div class="card z-index-2">
          <div class="p-3 card-body">
            <div v-if="licensa_selecionada != ''">
              <label for="">Filtro de licenças</label>
              <select name="" @change="handleLicenseFormSelect" class="form-group form-select" v-model="licensaform_selecionada" id="">
                <option disabled value="">Selecione...</option>
                <option value="">Não filtrar...</option>
                <option v-for="(licencasform, index) in licencasforms" :key=index :value="licencasform._id">{{ licencasform.name }}</option>
              </select>
            </div>
            <div v-else>
              <h4>Selecione um modelo acima...</h4>
            </div>
            <div v-if="licensaform_selecionada != ''">
              <reports-bar-chart
                id="chart-bar"
                title="Licença selecionada"
                description="Informações adicionais"
                :chart="{
                  labels: [
                    'Abr',
                    'Mai',
                    'Jun',
                    'Jul',
                    'Ago',
                    'Set',
                    'Out',
                    'Nov',
                    'Dez',
                  ],
                  datasets: {
                    label: 'Info3',
                    data: [450, 200, 100, 220, 500, 100, 400, 230, 500],
                  },
                }"
                :items="dashboardItems"
              />
            </div>
            </div>
        </div>
      </div> -->
      <div class="col-lg-8" style="text-align:center;">
        <div class="card z-index-2">
        <BarChart :key="barkey" :bardata="barChartData"/>        
        </div>
      </div>
    </div>
    <!-- <div class="row my-4">
      <div class="col-lg-8 col-md-6 mb-md-0 mb-4">
        <projects-card />
      </div>
      <div class="col-lg-4 col-md-6">
        <timeline-list
          class="h-100"
          title="Orders overview"
          description="<i class='fa fa-arrow-up text-success' aria-hidden='true'></i>
        <span class='font-weight-bold'>24%</span> this month"
        >
          <timeline-item
            color="success"
            icon="bell-55"
            title="$2400 Design changes"
            date-time="22 DEC 7:20 PM"
          />
          <TimelineItem
            color="danger"
            icon="html5"
            title="New order #1832412"
            date-time="21 DEC 11 PM"
          />
          <TimelineItem
            color="info"
            icon="cart"
            title="Server payments for April"
            date-time="21 DEC 9:34 PM"
          />
          <TimelineItem
            color="warning"
            icon="credit-card"
            title="New card added for order #4395133"
            date-time="20 DEC 2:20 AM"
          />
          <TimelineItem
            color="primary"
            icon="key-25"
            title="Unlock packages for development"
            date-time="18 DEC 4:54 AM"
          />
          <TimelineItem
            color="info"
            icon="check-bold"
            title="Notifications unread"
            date-time="15 DEC"
          />
        </timeline-list>
      </div>
    </div> -->
  </div>
</template>
<script>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
// import ReportsBarChart from "@/examples/Charts/ReportsBarChart.vue";
// import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
// import TimelineList from "./components/TimelineList.vue";
// import TimelineItem from "./components/TimelineItem.vue";
// import ProjectsCard from "./components/ProjectsCard.vue";
import US from "../assets/img/icons/flags/US.png";
import DE from "../assets/img/icons/flags/DE.png";
import GB from "../assets/img/icons/flags/GB.png";
import BR from "../assets/img/icons/flags/BR.png";
import LicencasDataService from "../services/LicencasDataService";
import LicencasFormsDataService from "../services/LicencasFormsDataService";
import DashboardDataService from "../services/DashboardDataService";
import BarChart from "./components/BarChart.vue";

import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
faCalendarDays,
faCalendarXmark,
faCalendarCheck,
faEye,
} from "@fortawesome/free-solid-svg-icons";
export default {
  name: "dashboard-default",
  data() {
    return {
      barkey: 0,
      barChartData: {
        type: "bar",
        data: {
          labels: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
          datasets: [
            {
              label: "Licenças cadastradas",
              data: [0,0,0,0,0,0,0,0,0,0,0,0],
              backgroundColor: "rgba(0, 130, 128, 0.4)",
              borderColor: "rgba(100, 255, 255, 0.3)",
              borderWidth: 4
            },
            // {
            //   label: "Planetary Mass (relative to the Sun x 10^-6)",
            //   data: [0.166, 2.081, 3.003, 0.323, 954.792, 285.886, 43.662, 51.514],
            //   backgroundColor: "rgba(71, 183,132,.5)",
            //   borderColor: "#47b784",
            //   borderWidth: 3
            // }
          ]
        },
        options: {
          responsive: true,
          lineTension: 1,
        }
      },
      dashboardData: {
                      "status": "carregando...",
                    },
      dashboardItems: [],
      licensaform_selecionada: "",
      licencasforms: "",
      licensa_selecionada: "",
      licencas: "",
      totalizadores: {
        createdCount: 0,
        renewedCount: 0,
        expiredCount: 0,
      },
      iconBackground: "bg-gradient-success",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      sales: {
        us: {
          country: "United States",
          sales: 2500,
          value: "$230,900",
          bounce: "29.9%",
          flag: US,
        },
        germany: {
          country: "Germany",
          sales: "3.900",
          value: "$440,000",
          bounce: "40.22%",
          flag: DE,
        },
        britain: {
          country: "Great Britain",
          sales: "1.400",
          value: "$190,700",
          bounce: "23.44%",
          flag: GB,
        },
        brasil: {
          country: "Brasil",
          sales: "562",
          value: "$143,960",
          bounce: "32.14%",
          flag: BR,
        },
      },
    };
  },
  methods: {
    getAll() {
      LicencasDataService.getAll({"active": true})
      .then(response => {
        this.licencas = response.data.license.data;
        console.log(this.licencas)
      })
      .catch(e => {
        console.log(e);
      });
    },
    handleLicenseSelect() {
      this.getBarChartData();
      this.licensaform_selecionada = "";
      this.getTotalizadores();
      this.getLicencasForms();
    },
    handleLicenseFormSelect() {
      this.getDashboardData();
    },
    getDashboardData() {
      DashboardDataService.getLicenseFormData(this.licensaform_selecionada)
      .then(response => {
        console.log(response)
        // this.totalizadores = response.data;
        this.dashboardData = response.data;
        this.dashboardItems = [
                        {
                          icon: {
                            color: 'success',
                            component: faEye,
                          },
                          label: 'Status',
                          progress: { content: response.data.status , percentage: 100 },
                        },
                        {
                          icon: { color: 'success', component: faCalendarCheck },
                          label: 'Data abertura',
                          progress: { content: response.data.licenseFormDates.openingDate.replaceAll("-","/"), percentage: 100 },
                        },
                        {
                          icon: { color: 'success', component: faCalendarXmark },
                          label: 'Data expiração',
                          progress: { content: response.data.licenseFormDates.validityDate.replaceAll("-","/"), percentage: 100 },
                        },
                        {
                          icon: { color: 'success', component: faCalendarDays },
                          label: 'Prazo de validade',
                          progress: { content: response.data.licenseFormDates.validityDays + " dias", percentage: 100 },
                        },
                      ]
      })
      .catch(e => {
        console.log(e);
      });
    },
    getBarChartData() {
      DashboardDataService.getBarChartData({year:2023, licenseId: this.licensa_selecionada})
      .then(response => {
        let chartData = [
                  response.data.data[0].Jan,
                  response.data.data[1].Fev,
                  response.data.data[2].Mar,
                  response.data.data[3].Abr,
                  response.data.data[4].Mai,
                  response.data.data[5].Jun,
                  response.data.data[6].Jul,
                  response.data.data[7].Ago,
                  response.data.data[8].Set,
                  response.data.data[9].Out,
                  response.data.data[10].Nov,
                  response.data.data[11].Dez,
                ];
        console.log(chartData)
        this.barChartData = {
          type: "bar",
          data: {
            labels: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
            datasets: [
              {
                label: "Licenças cadastradas",
                data: chartData,
                backgroundColor: "rgba(0, 130, 128, 0.4)",
                borderColor: "rgba(100, 255, 255, 0.3)",
                borderWidth: 4
              },
            ]
          },
          options: {
            responsive: true,
            lineTension: 1,
          }
        }
        this.barkey++;
      }).catch(e => {
        console.log(e);
      });
    },
    getTotalizadores() {
      let params = {_id: this.licensa_selecionada};
      DashboardDataService.getTotalizadores(params)
      .then(response => {
        this.totalizadores = response.data;
      })
      .catch(e => {
        console.log(e);
      });
    },
    getLicencasForms() {
      console.log(this.licensa_selecionada);
      LicencasFormsDataService.getAll({licenseId: this.licensa_selecionada})
      .then(response => {
        this.licencasforms = response.data.licenseForms.data;
        console.log(this.licencasforms);
      })
      .catch(e => {
        console.log(e);
      });
    },
  },
  components: {
    MiniStatisticsCard,
    BarChart
    // ProjectsCard,
    // TimelineList,
    // TimelineItem,
  },
  mounted (){
    this.getAll();
    this.getBarChartData();
    this.handleLicenseSelect();
    console.log(this.dashboardData);
  }
};
</script>
