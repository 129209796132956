<template>
    <div class="crud-modal">
        <div class="card card-plain">
            <div class="pb-0 card-header text-start"> 
                <h3 class="font-weight-bolder">
                Detalhes do modelo de licença
                </h3>
                <p class="mb-0"></p>
            </div>
            <div class="card-body">
                <form role="form" class="text-start">
                <div class="row">
                    <div class="col-md-6">
                        <label>Nome</label>
                        <p>{{ licenca.name }}</p>
                    </div>
                    <div class="col-md-6">
                        <label for="">Status</label>
                        <p v-if="licenca.isActive">Habilitada</p>
                        <p v-else>Desabilitada</p>
                    </div>
                </div>
                <hr>
                <!-- tipos -->
                <!-- subtipos -->
                <!-- finalidades -->
                <div class="row">
                    <div class="col-md-4">
                        <h3>Tipos</h3>
                        <ul v-if="tipos != ''">
                            <li v-for="(tipo, index) in tipos" :key="index">{{ tipo.name }}</li>
                        </ul>
                        <p v-else>Nenhum tipo cadastrado.</p>
                    </div>
                    <div class="col-md-4">
                        <h3>Sub-tipos</h3>
                        <ul v-if="subtipos != ''">
                            <li v-for="(subtipo, index) in subtipos" :key="index">{{ subtipo.name }}</li>
                        </ul>
                        <p v-else>Nenhum sub-tipo cadastrado.</p>
                    </div>
                    <div class="col-md-4">
                        <h3>Finalidades</h3>
                        <ul v-if="finalidades != ''">
                            <li v-for="(finalidade, index) in finalidades" :key="index">{{ finalidade.name }}</li>
                        </ul>
                        <p v-else>Nenhuma finalidade cadastrada.</p>
                    </div>
                </div>
                <div style="text-align: right;">
                    <a href="#" class="btn btn-default" @click="close">
                        Fechar
                    </a>
                </div>
                </form>
            </div> 
        </div>
    </div>
</template>
<style>
    .modal-container {
        z-index: 93949349394349;
    }
    .modal-item {
        width: 50%;
    }
</style>
<script>
    import {closeModal} from "jenesius-vue-modal"
    import LicencasDataService from "../../services/LicencasDataService";
    export default {
        data() {
            return {
                licenca: "",
                tipos: "",
                subtipos: "",
                finalidades: "",
            }
        },
        props: {
            idlicenca: String
        },
        methods: {
            fetchLicenca() {
                LicencasDataService.show(this.idlicenca)
                .then(response => {
                    console.log(response);
                    this.licenca = response.data.license;
                    this.tipos = response.data.types;
                    this.subtipos = response.data.subTypes;
                    this.finalidades = response.data.finalities;
                })
                .catch(e => {
                    console.log(e);
                });
            },
            close() {
                closeModal();
            }
        },
        mounted() {
            this.fetchLicenca();
        }
    }
</script>