<template>
  <div
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse navText="Dashboard" :to="{ name: 'Dashboard' }">
          <template #icon>
            <shop />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="String(permissions.indexOf('admin')) != -1">
        <sidenav-collapse navText="Usuários" :to="{ name: 'Usuarios' }">
          <template #icon>
            <!-- <office /> -->
            <customer-support />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="String(permissions.indexOf('admin')) != -1">
        <sidenav-collapse navText="Perfis / Permissões" :to="{ name: 'Perfis' }">
          <template #icon>
            <switches />
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item" v-if="String(permissions.indexOf('admin')) != -1">
        <sidenav-collapse
          navText="Unidades de lotação"
          :to="{ name: 'Unidades' }"
        >
          <template #icon>
            <Office />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="String(permissions.indexOf('admin')) != -1">
        <sidenav-collapse
          navText="Modelos de licença"
          :to="{ name: 'Modelos'}"
        >
          <template #icon>
            <Document />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          navText="Licenças"
          :to="{ name: 'LicencasForms'}"
        >
          <template #icon>
            <Document />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          navText="Relatórios"
          :to="{ name: 'Relatorios'}"
        >
          <template #icon>
            <Document />
          </template>
        </sidenav-collapse>
      </li>
      <!-- <li class="mt-3 nav-item">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'"
        >
          PAGES
        </h6>
      </li> -->
      <!-- <li class="nav-item">
        <sidenav-collapse navText="Profile" :to="{ name: 'Profile' }">
          <template #icon>
            <customer-support />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="Sign In" :to="{ name: 'Sign In' }">
          <template #icon>
            <document />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse navText="Sign Up" :to="{ name: 'Sign Up' }">
          <template #icon>
            <spaceship />
          </template>
        </sidenav-collapse>
      </li> -->
    </ul>
  </div>
  <!-- <div class="pt-3 mx-3 mt-3 sidenav-footer">
    <sidenav-card
      :class="cardBg"
      textPrimary="Need Help?"
      textSecondary="Please check our docs"
      route="https://www.creative-tim.com/learning-lab/vue/overview/soft-ui-dashboard/"
      label="Documentation"
      icon="ni ni-diamond"
    />
    <a
      class="btn bg-gradient-success mt-4 w-100"
      href="https://www.creative-tim.com/product/vue-soft-ui-dashboard-pro?ref=vsud"
      type="button"
      >Upgrade to pro</a
    >
  </div> -->
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
// import SidenavCard from "./SidenavCard.vue";
import Shop from "../../components/Icon/Shop.vue";
// import Office from "../../components/Icon/Office.vue";
import Switches from "../../components/Icon/Switches.vue";
import Office from "../../components/Icon/Office.vue";
import CustomerSupport from "../../components/Icon/CustomerSupport.vue";
import Document from "../../components/Icon/Document.vue";
// import Spaceship from "../../components/Icon/Spaceship.vue";
// import Settings from "../../components/Icon/Settings.vue";

export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "Soft UI Dashboard PRO",
      controls: "dashboardsExamples",
      isActive: "active",
      permissions: "",
    };
  },
  components: {
    SidenavCollapse,
    // SidenavCard,
    Shop,
    // Office,
    Switches,
    Office,
    CustomerSupport,
    Document,
    // Spaceship,
    // Settings,
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
  mounted(){
    this.permissions = window.localStorage.getItem("permissions");
  }
};
</script>
