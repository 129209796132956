<template>
    <div class="crud-modal">
        <div class="card card-plain">

                <div class="pb-0 card-header text-start"> 
                  <h3 class="font-weight-bolder">
                    Novo modelo de finalidade
                  </h3>
                  <p class="mb-0">Insira os dados abaixo.</p>
                </div>
                <div class="card-body">
                  <form role="form" class="text-start">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Nome</label>
                            <soft-input
                            id="Nome"
                            type="text"
                            placeholder="Insira o nome..."
                            name="Nome"
                            v-model:value="finalidade.name"
                            />
                        </div>
                        <div class="col-md-6">
                            <!-- <label>Senha</label>
                            <soft-input
                            id="password"
                            type="password"
                            placeholder="Senha"
                            name="password"
                            /> -->
                            <!-- <label for="">Status</label> -->
                            <!-- <select v-model="unidade.isActive" name="status" id="status" class="form-select form-control"> -->
                                <!-- <option selected value=true>Habilitada</option> -->
                                <!-- <option value=false>Desabilitada</option> -->
                            <!-- </select> -->
                        </div>
                    </div>
                    <hr>
                    <div style="text-align:right;">
                        <a href="#"
                            class="btn btn-success"
                            @click="addTableRow"
                        >
                            <font-awesome-icon icon="fa-solid fa-user-plus" />
                            &nbsp; Adicionar campo
                        </a>
                    </div>
                    <h4>Campos</h4>
                    <div class="table-responsive p-0 ">
                        <table class="table align-items-center mb-0">
                            <thead>
                                <tr>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Tipo</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Ordem</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Título</th>
                                    <!-- <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Obrigatório</th> -->
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Texto de ajuda</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Texto interno</th>
                                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Máscara</th>
                                    <!-- <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Validador</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(content, index) in tableRows" :key="index">
                                    <td>
                                        <select v-model="content.fieldType" style="width:120px" class="form-group form-control form-select campo-fieldType" name="" id="">
                                            <option disabled value="" selected>Selecione...</option>
                                            <option value="text">Texto</option>
                                            <!-- <option value="number">Número</option> -->
                                            <option value="date">Data</option>
                                            <option value="textarea">Área</option>
                                            <!-- <option value="phone">Telefone</option> -->
                                            <!-- <option value="file">Arquivo</option> -->
                                        </select>
                                    </td>
                                    <td>
                                        <soft-input
                                            class="campo-order"
                                            type="number"
                                            placeholder="Insira a ordem..."
                                            name="Nome"
                                            v-model:value="content.order"
                                        />
                                    </td>
                                    <td>
                                        <soft-input
                                            class="campo-title"
                                            type="text"
                                            placeholder="Insira o título..."
                                            name="Nome"
                                            v-model:value="content.title"
                                        />
                                    </td>
                                    <!-- <td>
                                        <select v-model="content.required" style="width:120px" class="form-group form-control form-select campo-required" name="" id="">
                                            <option disabled value="" selected>Selecione...</option>
                                            <option value=true>Sim</option>
                                            <option value=false>Não</option>
                                        </select>
                                    </td> -->
                                    <td>
                                        <soft-input
                                            class="campo-helpText"
                                            type="text"
                                            placeholder="Insira o texto de ajuda..."
                                            name="Nome"
                                            v-model:value="content.helpText"
                                        />
                                    </td>
                                    <td>
                                        <soft-input
                                            class="campo-innerText"
                                            type="text"
                                            placeholder="Insira o texto interno..."
                                            name="Nome"
                                            v-model:value="content.innerText"
                                        />
                                    </td>
                                    <td>
                                        <!-- <soft-input
                                            class="campo-mask"
                                            type="text"
                                            placeholder="Insira a máscara..."
                                            name="Nome"
                                            v-model:value="content.mask"
                                        /> -->
                                        <select v-if="content.fieldType == 'text'" v-model="content.mask" style="width:120px" class="form-group form-control form-select campo-required" name="" id="">
                                            <option disabled value="" selected>Selecione...</option>
                                            <option value="###.###.###-##">CPF</option>
                                            <option value="##.###.###/####-##">CNPJ</option>
                                            <option value="(##) #####-####">Celular (com 9º dígito)</option>
                                            <option value="(##) ####-####">Telefone (sem 9º dígito)</option>
                                        </select>
                                        <small v-else>
                                            Máscaras só podem ser utilizadas em campos texto.
                                        </small>
                                    </td>
                                    <!-- <td>
                                        <soft-input
                                            class="campo-validator"
                                            type="text"
                                            placeholder="Insira o validador..."
                                            name="Nome"
                                            v-model:value="content.validator"
                                        />
                                    </td> -->
                                    <td>
                                    <a href="#" class="btn btn-danger" @click="deleteTableRow(index)">
                                        <font-awesome-icon icon="fa-solid fa-x" />
                                    </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <hr>
                    <div style="text-align: right;">
                        <a href="#" class="btn btn-success"
                        @click="create">
                            Salvar
                        </a>
                        <a href="#" class="btn btn-default" @click="close">
                            Cancelar
                        </a>
                    </div>
                  </form>
                </div> 
              </div>
    </div>
</template>
<style>
    .modal-container {
        z-index: 93949349394349;
    }
    .modal-item {
        width: 50%;
    }
</style>
<script>
    import SoftInput from "@/components/SoftInput.vue";
    import {closeModal} from "jenesius-vue-modal"
    import FinalidadesDataService from "../../services/FinalidadesDataService";

    export default {
        data() {
            return {
               finalidade:  {
                "name" : "",
                "parentId": "",
                // "isActive" : true,
               },
               tableRows: [{
                    "fieldType": "",
                    "order": "",
                    "title": "",
                    "required": true,
                    "helpText": "",
                    "innerText": "",
                    "mask": "",
                    // "validator": "", 
                }],
               fields: {},
            }
        },
        props: {
            parentid: String
        },
        components: {
            SoftInput,
        },
        methods: {
            close() {
                closeModal();
            },
            addTableRow() {
                let fieldObject = {
                    "fieldType": "",
                    "order": "",
                    "title": "",
                    "required": true,
                    "helpText": "",
                    "innerText": "",
                    "mask": "",
                    // "validator": "", 
                };
                this.tableRows.push(fieldObject);
            },
            deleteTableRow(index) {
                this.tableRows.splice(index, 1);
            },
            create() {
                let model = {
                    name : this.finalidade.name,
                    parentId : this.finalidade.parentId,
                    fields : this.tableRows,
                }
                FinalidadesDataService.create(model)
                .then(response => {
                    console.log(response)
                    this.$emit("create");
                    this.close();
                })
                .catch(e => {
                    console.log(e);
                });
            },
        },
        mounted() {
            this.finalidade.parentId = this.parentid
        }
    }
</script>