/**
=========================================================
* Vue Soft UI Dashboard - v3.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-soft-ui-dashboard
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import SoftUIDashboard from "./soft-ui-dashboard";

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
/* import specific icons */
import { faEdit, faSitemap,faX,faTrashAlt,faEye,faSync,faUserPlus,faPlusSquare,faArrowLeft, faFileExport, faFileAlt, faList12, faListAlt, faFileExcel, faFilePdf, faCheckCircle, faUndo, faDownload, faUserCircle, faUpload, faTrash} from '@fortawesome/free-solid-svg-icons';
import Toast from "vue-toastification";
import * as dotenv from 'dotenv';
import Wizard from 'form-wizard-vue3';
import './assets/css/form-wizard.css';
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
/* add icons to the library */
library.add(faEdit, faEye, faUndo, faUserCircle, faUpload, faTrash, faDownload,faCheckCircle,faFileExcel, faFilePdf, faSync, faUserPlus, faPlusSquare, faSitemap, faArrowLeft, faX, faTrashAlt, faFileExport, faFileAlt, faList12, faListAlt);
dotenv.config();

/* add font awesome icon component */
const appInstance = createApp(App);
appInstance.use(VueSweetalert2);
appInstance.component('font-awesome-icon', FontAwesomeIcon)
appInstance.component('Wizard', Wizard);
appInstance.use(store);
appInstance.use(router);
appInstance.use(SoftUIDashboard);
appInstance.use(Toast);
appInstance.mount("#app");
