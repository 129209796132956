<template>
    <div class="crud-modal">
        <Wizard
        squared-tabs
        card-background
        navigable-tabs
        scrollable-tabs
        :nextButton="{
          text: 'Próximo',
          icon: 'chevron-right',
          hideIcon: false, // default false but selected for sample
          hideText: false, // default false but selected for sample
        }"
        :backButton="{
          text: 'Anterior',
          icon: 'chevron-left',
          hideIcon: false, // default false but selected for sample
          hideText: false, // default false but selected for sample
        }"
        :doneButton="{
          text: 'Finalizar',
          icon: 'check-square',
          hideIcon: false, // default false but selected for sample
          hideText: false, // default false but selected for sample
        }"
        :custom-tabs="[
            {
            title: 'Licença',
            // icon: 'map'
            },
            {
            title: 'Tipo',
            // icon: 'map'
            },
            {
            title: 'Sub-tipo',
            },
            {
            title: 'Finalidade',
            },
        ]"
        :beforeChange="onTabBeforeChange"
        @change="onChangeCurrentTab"
        @complete:wizard="wizardCompleted"
      >
        <div v-if="currentTabIndex === 0">
            <form role="form" class="text-start">
                <div class="row">
                    <div class="col-md-6">
                        <label>Nome</label>
                        <soft-input
                        id="Nome"
                        type="text"
                        placeholder="Insira o nome..."
                        name="Nome"
                        v-model:value="licencaform.name"
                        />
                    </div>
                    <div class="col-md-2">
                        <label for="">Data de validade</label>
                        <soft-input
                            id="dueDate"
                            type="date"
                            placeholder="..."
                            name="dueDate"
                            v-model:value="licencaform.dueDate"
                        />
                    </div>  
                    <div class="col-md-2">
                        <label for="">Licença</label>
                        <select v-model="licencaform.licenseId" @change="getLicenceTypes" name="licenca" id="licenca" class="form-select form-control">
                            <option disabled value="">Selecione...</option>
                            <option v-for="(licenca, index) in licencas" :key="index" :value='licenca._id'>{{ licenca.name  }}</option>
                        </select>
                    </div>  
                </div>
            </form>
        </div>
        <!-- TIPO -->
        <div v-show="currentTabIndex === 1 && licencaform.licenseId != ''">
            <div class="col-md-2">
                <label for="">Tipo</label>
                <select v-model="licencaform.formData.typeId" @change="handleTypeSelection" name="tipo" id="tipo" class="form-select form-control">
                    <option disabled value="">Selecione...</option>
                    <option v-for="(tipo, index) in tipos" :key="index" :value='tipo._id'>{{ tipo.name  }}</option>
                </select>
            </div>
            <div v-if="licencaform.formData.typeId != ''">
                <br>
                <hr>
                <h3>Campos</h3>
                <div class="row">
                    <div v-for="(field, index) in tipo_fields" :key="index" class="col-md-3 typefield">
                        <label for="{{ field._id }}">{{ field.title }} <span v-if="field.required == 'true'" style="color: red;">*</span></label>
                        <soft-input
                            :id="field._id"
                            :type="field.fieldType" 
                            :placeholder="field.innerText" 
                            :name="field.title"
                            :isrequired="field.required"
                            posicao="tipo"
                            :mask="field.mask"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="currentTabIndex === 1 && licencaform.licenseId == ''">
            <h3 style="text-align:center;">Você precisa selecionar a licença para preencher a parte de tipo.</h3>
        </div>
        <!-- TIPO END -->
        <!-- SUB-TIPO -->
        <div v-show="currentTabIndex === 2 && licencaform.formData.typeId != ''">
            <div class="col-md-2">
                <label for="">Sub-tipo</label>
                <select v-model="licencaform.formData.subTypeId" @change="handleSubTypeSelection" name="subtipo" id="subtipo" class="form-select form-control">
                    <option disabled value="">Selecione...</option>
                    <option v-for="(subtipo, index) in subtipos" :key="index" :value='subtipo._id'>{{ subtipo.name  }}</option>
                </select>
            </div> 
            <div v-if="licencaform.formData.subTypeId != ''">
                <br>
                <hr>
                <h3>Campos</h3>
                <div class="row">
                    <div v-for="(field, index) in subtipo_fields" :key="index" class="col-md-3 subtypefield">
                        <label>{{ field.title }} <span v-if="field.required == 'true'" style="color: red;">*</span></label>
                        <soft-input
                            :id="field._id"
                            :type="field.fieldType" 
                            :placeholder="field.innerText" 
                            :name="field.title"
                            :isrequired="field.required"
                            posicao="subtipo"
                            :mask="field.mask"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="currentTabIndex === 2 && licencaform.formData.typeId == ''">
            <h3 style="text-align:center;">Você precisa selecionar o tipo para preencher a parte de sub-tipo.</h3>
        </div>
        <!-- SUB-TIPO END -->
        <!-- FINALIDADE START -->
        <div v-show="currentTabIndex === 3 && licencaform.formData.subTypeId != ''">
            <div class="col-md-2">
                <label for="">Finalidade</label>
                <select v-model="licencaform.formData.finalityId" @change="handleFinalitySelection" name="finalidade" id="finalidade" class="form-select form-control">
                    <option disabled value="">Selecione...</option>
                    <option v-for="(finalidade, index) in finalidades" :key="index" :value='finalidade._id'>{{ finalidade.name  }}</option>
                </select>
            </div>
            <div v-if="licencaform.formData.finalityId != ''">
                <br>
                <hr>
                <h3>Campos</h3>
                <div class="row">
                    <div v-for="(field, index) in finalidade_fields" :key="index" class="col-md-3 finalityfield">
                        <label>{{ field.title }} <span v-if="field.required == 'true'" style="color: red;">*</span></label>
                        <soft-input
                            :id="field._id"
                            :type="field.fieldType" 
                            :placeholder="field.innerText" 
                            :name="field.title"
                            :isrequired="field.required"
                            posicao="finalidade"
                            :mask="field.mask"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="currentTabIndex === 3 && licencaform.formData.subTypeId == ''">
            <h3 style="text-align:center;">Você precisa selecionar o sub-tipo para preencher a parte de finalidade.</h3>
        </div>
        <!-- FINALIDADE END -->
      </Wizard>
    </div>
</template>
<style>
    .modal-container {
        z-index: 93949349394349;
    }
    .modal-item {
        width: 50%;
    }
</style>
<script>
    import SoftInput from "@/components/SoftInput.vue";
    import {closeModal} from "jenesius-vue-modal"
    import LicencasDataService from "../../services/LicencasDataService";
    import LicencasFormsDataService from "../../services/LicencasFormsDataService";
    import TiposDataService from "../../services/TiposDataService";
    import SubtiposDataService from "../../services/SubtiposDataService";
    import FinalidadesDataService from "../../services/FinalidadesDataService";

    export default {
        data() {
            return {
                typefield: "typefield form-control",
                subtypefield: "subtypefield form-control",
                finalityfield: "finalityfield form-control",
                currentTabIndex: 0,
                params: {"page" : 1},
                licencas: [],
                tipos: [],
                tipo_selecionado: "",
                tipo_fields: "",
                subtipos: [],
                subtipo_selecionado: "",
                subtipo_fields: "",
                finalidades: [],
                finalidade_selecionada: "",
                finalidade_fields: "",
                licencaform: {
                    name: "",
                    dueDate:"",
                    licenseId: "",
                    formData: {
                        typeId: "",
                        typeData: [], 
                        subTypeId: "",
                        subTypeData: [],
                        finalityId: "",
                        finalityData: []
                    }
                }
            }
        },
        props: {
            title: String
        },
        components: {
            SoftInput,
        },
        methods: {
            onChangeCurrentTab(index, oldIndex) {
                console.log(index, oldIndex);
                this.currentTabIndex = index;
            },
            onTabBeforeChange() {
                
            },
            fetchFieldsFromDom(domList) {
                let typeData = Array.from(this.licencaform.formData.typeData);
                let subTypeData = Array.from(this.licencaform.formData.subTypeData);
                let finalityData = Array.from(this.licencaform.formData.finalityData);
                
                for (const key in domList) {
                    if (Object.hasOwnProperty.call(domList, key)) {
                        const element = domList[key];
                        console.log(element.getAttribute("posicao"))
                        // console.log(element.toString().match(/posicao="((?:\\.|[^"\\])*)"/));
                        switch (element.getAttribute("posicao")) {
                            case 'tipo':
                                typeData.push(
                                    {
                                        "fieldId" : element.id,
                                        "key" : element.name,
                                        "value": element.value,
                                        "fieldType": element.type,
                                    }
                                );       
                                break;
                            case 'subtipo':
                                subTypeData.push(
                                    {
                                        "fieldId" : element.id,
                                        "key" : element.name,
                                        "value": element.value,
                                        "fieldType": element.type,
                                    }
                                );
                                break;
                            case 'finalidade':
                                finalityData.push(
                                    {
                                        "fieldId" : element.id,
                                        "key" : element.name,
                                        "value": element.value,
                                        "fieldType": element.type,
                                    }
                                );
                                break;
                            default:
                                console.log("caso não implementado");
                                break;
                        }

                    }
                }

                this.licencaform.formData.typeData = typeData;
                this.licencaform.formData.subTypeData = subTypeData;
                this.licencaform.formData.finalityData = finalityData;
                // console.log(this.licencaform.formData);

            },
            wizardCompleted() {
                this.fetchFieldsFromDom(this.$el.querySelectorAll('input'));
                this.create();
                
            },
            close() {
                closeModal();
            },
            getAllLicenses() {
                LicencasDataService.getAll({"active": true})
                .then(response => {
                    console.log(response);
                    this.licencas = response.data.license.data;
                })
                .catch(e => {
                    console.log(e);
                });
            },
            getLicenceTypes() {
                this.licencaform.formData.typeId = "";       
                this.licencaform.formData.typeData = "";                        

                this.licencaform.formData.subTypeId = "";                        
                this.licencaform.formData.subTypeData = "";                        

                this.licencaform.formData.finalityId = "";                    
                this.licencaform.formData.finalityData = "";                    

                LicencasDataService.show(this.licencaform.licenseId)
                .then(response => {
                    console.log(response);
                    this.tipos = response.data.types;
                })
                .catch(e => {
                    console.log(e);
                });
            },
            handleTypeSelection() {
                this.licencaform.formData.subTypeId = "";   
                this.licencaform.formData.subTypeData = "";                        
    
                
                this.licencaform.formData.finalityId = "";
                this.licencaform.formData.finalityData = "";                    


                TiposDataService.show(this.licencaform.formData.typeId)
                .then((response) => {
                    console.log(response);
                    this.tipo_selecionado = response.data;
                    this.tipo_fields = response.data.fields;
                    this.subtipos = response.data.subTypes;
                }).catch(e => {
                    console.log(e)
                });
            },  
            handleSubTypeSelection() {
                this.licencaform.formData.finalityId = "";
                this.licencaform.formData.finalityData = "";                    

                SubtiposDataService.show(this.licencaform.formData.subTypeId)
                .then((response) => {
                    console.log(response);
                    this.subtipo_selecionado = response.data;
                    this.subtipo_fields = response.data.fields;
                    this.finalidades = response.data.finalities;
                }).catch(e => {
                    console.log(e)
                });
            },
            handleFinalitySelection() {
                FinalidadesDataService.show(this.licencaform.formData.finalityId)
                .then((response) => {
                    console.log(response);
                    this.finalidade_selecionada = response.data;
                    this.finalidade_fields = response.data.fields;
                }).catch(e => {
                    console.log(e)
                });
            },  
            create() {
                console.log(this.licencaform)
                LicencasFormsDataService.create(this.licencaform)
                .then(() => {
                    this.$emit("create");
                    this.close();
                })
                .catch(e => {
                    console.log(e);
                });
            },
        },
        mounted() {
            this.getAllLicenses(this.params);
        }
    }
</script>