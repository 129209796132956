<template>
    <div class="crud-modal">
        <div class="card card-plain">

                <div class="pb-0 card-header text-start"> 
                  <h3 class="font-weight-bolder">
                    Novo perfil
                  </h3>
                  <p class="mb-0">Insira os dados abaixo.</p>
                </div>
                <div class="card-body">
                  <form role="form" class="text-start">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Nome</label>
                            <soft-input
                            id="Nome"
                            type="text"
                            placeholder="Insira o nome..."
                            name="Nome"
                            v-model:value="profile.name"
                            />
                        </div>
                        <div class="col-md-6">
                            <label>Estagiário</label>
                            <select class="form-control form-select" name="isTrainee" v-model="profile.isTrainee" id="">
                                <option disabled value="">Selecione...</option>
                                <option value=false>Não</option>
                                <option value=true>Sim</option>
                            </select>
                        </div>
                    </div>
                    <hr>
                    <div class="row" style="overflow:auto;">
                        <div class="col-md-2">
                            
                            <soft-checkbox
                                @click="addOrRemoveAllPermissions('user')"
                                id="user-all-permissions"
                                name="user-all-permissions"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemoveAllPermissions('user')"><h4>Usuários</h4></b>
                            </soft-checkbox>
                            <div v-show="!userAllPermissions">
                                <soft-checkbox
                                    @click="addOrRemovePermission('user-index')"
                                    id="user-index"
                                    name="user-index"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('user-index')">Ver listagem</b>
                                </soft-checkbox>
                                <soft-checkbox
                                    @click="addOrRemovePermission('user-store')"
                                    id="user-store"
                                    name="user-store"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('user-store')">Cadastrar</b>
                                </soft-checkbox>
                                <soft-checkbox
                                    @click="addOrRemovePermission('user-update')"
                                    id="user-update"
                                    name="user-update"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('user-update')">Editar</b>
                                </soft-checkbox>
                                <soft-checkbox
                                    @click="addOrRemovePermission('user-deactivate')"
                                    id="user-deactivate"
                                    name="user-deactivate"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('user-deactivate')">Desativar</b>
                                </soft-checkbox>
                                <soft-checkbox
                                    @click="addOrRemovePermission('user-activate')"
                                    id="user-activate"
                                    name="user-activate"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('user-activate')">Ativar</b>
                                </soft-checkbox>
                            </div>
                            <div v-show="userAllPermissions">
                                <h5>Todas as permissões habilitadas</h5>
                            </div>
                        </div>
                        <!-- end usuarios -->
                        <!-- start unidades de lotação -->
                        <div class="col-md-2">
                            <soft-checkbox
                                @click="addOrRemoveAllPermissions('und_lotacao')"
                                id="und_lotacao-all-permissions"
                                name="und_lotacao-all-permissions"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemoveAllPermissions('und_lotacao')"><h4>Unidades de Lotação</h4></b>
                            </soft-checkbox>
                            <div v-show="!undLotacaoAllPermissions">
                                <soft-checkbox
                                    @click="addOrRemovePermission('und_lotacao-index')"
                                    id="und_lotacao-index"
                                    name="und_lotacao-index"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('und_lotacao-index')">Ver listagem</b>
                                </soft-checkbox>
                                <soft-checkbox
                                    @click="addOrRemovePermission('und_lotacao-store')"
                                    id="und_lotacao-store"
                                    name="und_lotacao-store"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('und_lotacao-store')">Cadastrar</b>
                                </soft-checkbox>
                                <soft-checkbox
                                    @click="addOrRemovePermission('und_lotacao-update')"
                                    id="und_lotacao-update"
                                    name="und_lotacao-update"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('und_lotacao-update')">Editar</b>
                                </soft-checkbox>
                                <soft-checkbox
                                    @click="addOrRemovePermission('und_lotacao-deactivate')"
                                    id="und_lotacao-deactivate"
                                    name="und_lotacao-deactivate"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('und_lotacao-deactivate')">Desativar</b>
                                </soft-checkbox>
                                <soft-checkbox
                                    @click="addOrRemovePermission('und_lotacao-activate')"
                                    id="und_lotacao-activate"
                                    name="und_lotacao-activate"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('und_lotacao-activate')">Ativar</b>
                                </soft-checkbox>
                            </div>
                            <div v-show="undLotacaoAllPermissions">
                                <h5>Todas as permissões habilitadas</h5>
                            </div>
                        </div>
                        <!-- end unidades de lotação -->
                        <!-- start profiles -->
                        <div class="col-md-2">
                            <soft-checkbox
                                @click="addOrRemoveAllPermissions('profile')"
                                id="profile-all-permissions"
                                name="profile-all-permissions"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemoveAllPermissions('profile')"><h4>Perfis / Permissões</h4></b>
                            </soft-checkbox>
                            <div v-show="!profilesAllPermissions">
                                <soft-checkbox
                                    @click="addOrRemovePermission('profile-index')"
                                    id="profile-index"
                                    name="profile-index"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('profile-index')">Ver listagem</b>
                                </soft-checkbox>
                                <soft-checkbox
                                    @click="addOrRemovePermission('profile-store')"
                                    id="profile-store"
                                    name="profile-store"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('profile-store')">Cadastrar</b>
                                </soft-checkbox>
                                <soft-checkbox
                                    @click="addOrRemovePermission('profile-update')"
                                    id="profile-update"
                                    name="profile-update"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('profile-update')">Editar</b>
                                </soft-checkbox>
                                <soft-checkbox
                                    @click="addOrRemovePermission('profile-delete')"
                                    id="profile-delete"
                                    name="profile-deleteate"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('profile-delete')">Excluir</b>
                                </soft-checkbox>
                            </div>
                            <div v-show="profilesAllPermissions">
                                <h5>Todas as permissões habilitadas.</h5>
                            </div>
                        </div>
                        <!-- end profiles -->
                         <!-- modelos -->
                         <div class="col-md-2">
                            <soft-checkbox
                                @click="addOrRemoveAllPermissions('license')"
                                id="license-all-permissions"
                                name="license-all-permissions"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemoveAllPermissions('license')"><h4>Modelos de licença</h4></b>
                            </soft-checkbox>
                            <div v-show="!licenseAllPermissions">
                                <soft-checkbox
                                    @click="addOrRemovePermission('license-index')"
                                    id="modelos-index"
                                    name="modelos-index"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('license-index')">Ver listagem</b>
                                </soft-checkbox>
                                <soft-checkbox
                                    @click="addOrRemovePermission('license-store')"
                                    id="modelos-store"
                                    name="modelos-store"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('license-store')">Cadastrar</b>
                                </soft-checkbox>
                                <!-- <soft-checkbox
                                    @click="addOrRemovePermission('license-show')"
                                    id="modelos-show"
                                    name="modelos-show"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('license-show')">Ver detalhes</b>
                                </soft-checkbox> -->
                                <soft-checkbox
                                    @click="addOrRemovePermission('license-update')"
                                    id="modelos-update"
                                    name="modelos-update"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('license-update')">Editar</b>
                                </soft-checkbox>
                                <soft-checkbox
                                    @click="addOrRemovePermission('license-deactivate')"
                                    id="modelos-deactivate"
                                    name="modelos-deactivate"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('license-deactivate')">Desativar</b>
                                </soft-checkbox>
                                <soft-checkbox
                                    @click="addOrRemovePermission('license-activate')"
                                    id="modelos-activate"
                                    name="modelos-activate"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('license-activate')">Ativar</b>
                                </soft-checkbox>
                                <soft-checkbox
                                    @click="addOrRemovePermission('field-delete')"
                                    id="modelos-delete-fields"
                                    name="modelos-delete-fields"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('field-delete')">Excluir campos</b>
                                </soft-checkbox>
                            </div>
                            <div v-show="licenseAllPermissions">
                                <h5>Todas as permissões habilitadas.</h5>
                            </div>
                        </div>
                            
                        <!-- end modelos -->
                        <!-- licencas -->
                        <div class="col-md-2">
                            <soft-checkbox
                                @click="addOrRemoveAllPermissions('license_form')"
                                id="license_form-all-permissions"
                                name="license_form-all-permissions"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemoveAllPermissions('license_form')"><h4>Licenças (formulário)</h4></b>
                            </soft-checkbox>
                            <div v-show="!licenseFormAllPermissions">
                                <soft-checkbox
                                    @click="addOrRemovePermission('license_form-index')"
                                    id="licenceforms-index"
                                    name="licenceforms-index"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('license_form-index')">Ver listagem</b>
                                </soft-checkbox>
                                <soft-checkbox
                                    @click="addOrRemovePermission('license_form-store')"
                                    id="licenceforms-store"
                                    name="licenceforms-store"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('license_form-store')">Cadastrar</b>
                                </soft-checkbox>
                                <!-- <soft-checkbox
                                    @click="addOrRemovePermission('license_form-show')"
                                    id="licenceforms-show"
                                    name="licenceforms-show"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('license_form-show')">Ver detalhes</b>
                                </soft-checkbox> -->
                                <soft-checkbox
                                    @click="addOrRemovePermission('license_form-delete')"
                                    id="licenceforms-delete"
                                    name="licenceforms-deleteate"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('license_form-delete')">Excluir</b>
                                </soft-checkbox>
                                <soft-checkbox
                                    @click="addOrRemovePermission('license_form-active')"
                                    id="licenceforms-active"
                                    name="licenceforms-active"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('license_form-active')">Habilitar</b>
                                </soft-checkbox>
                                <soft-checkbox
                                    @click="addOrRemovePermission('license_form-inactive')"
                                    id="licenceforms-inactive"
                                    name="licenceforms-inactive"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('license_form-inactive')">Desabilitar</b>
                                </soft-checkbox>
                                <soft-checkbox
                                    @click="addOrRemovePermission('license_form-update')"
                                    id="licenceforms-edit"
                                    name="licenceforms-edit"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('license_form-update')">Editar</b>
                                </soft-checkbox>
                                <soft-checkbox
                                    @click="addOrRemovePermission('license_form-request')"
                                    id="licenceforms-requests"
                                    name="licenceforms-requests"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('license_form-request')">Ver solicitações</b>
                                </soft-checkbox>
                                <soft-checkbox
                                    @click="addOrRemovePermission('license_form-renew')"
                                    id="licenceforms-renew"
                                    name="licenceforms-renew"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('license_form-renew')">Renovar</b>
                                </soft-checkbox>
                                <soft-checkbox
                                    @click="addOrRemovePermission('license_form-accept_request')"
                                    id="licenceforms-acceptrequest"
                                    name="licenceforms-acceptrequest"
                                    class="font-weight-light"
                                >
                                    <b @click="addOrRemovePermission('license_form-accept_request')">Aprovar solicitação</b>
                                </soft-checkbox>
                            </div>
                            <div v-show="licenseFormAllPermissions">
                                <h5>Todas as permissões habilitadas.</h5>
                            </div>
                        </div>
                        <!-- end licencas -->
                    </div>
                    <div class="row">
                        <!-- tipos -->
                        <!-- <div class="col-md-2">
                            <h4>Modelos de tipo</h4>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_type-index')"
                                id="tipos-index"
                                name="tipos-index"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_type-index')">Ver listagem</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_type-store')"
                                id="tipos-store"
                                name="tipos-store"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_type-store')">Cadastrar</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_type-show')"
                                id="tipos-show"
                                name="tipos-show"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_type-show')">Ver detalhes</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_type-update')"
                                id="tipos-update"
                                name="tipos-update"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_type-update')">Editar</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_type-delete')"
                                id="tipos-delete"
                                name="tipos-deleteate"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_type-delete')">Excluir</b>
                            </soft-checkbox>
                        </div> -->
                        <!-- end tipos -->
                        <!-- subtipos -->
                        <!-- <div class="col-md-2">
                            <h4>Modelos de sub-tipo</h4>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_sub_type-index')"
                                id="subtipos-index"
                                name="subtipos-index"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_sub_type-index')">Ver listagem</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_sub_type-store')"
                                id="subtipos-store"
                                name="subtipos-store"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_sub_type-store')">Cadastrar</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_sub_type-show')"
                                id="subtipos-show"
                                name="subtipos-show"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_sub_type-show')">Ver detalhes</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_sub_type-update')"
                                id="subtipos-update"
                                name="subtipos-update"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_sub_type-update')">Editar</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_sub_type-delete')"
                                id="subtipos-delete"
                                name="subtipos-deleteate"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_sub_type-delete')">Excluir</b>
                            </soft-checkbox>
                        </div> -->
                        <!-- end subtipos -->
                        <!-- finalidades -->
                        <!-- <div class="col-md-2">
                            <h4>Modelos de finalidade</h4>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_finality-index')"
                                id="finalidades-index"
                                name="finalidades-index"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_finality-index')">Ver listagem</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_finality-store')"
                                id="finalidades-store"
                                name="finalidades-store"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_finality-store')">Cadastrar</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_finality-show')"
                                id="finalidades-show"
                                name="finalidades-show"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_finality-show')">Ver detalhes</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_finality-update')"
                                id="finalidades-update"
                                name="finalidades-update"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_finality-update')">Editar</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_finality-delete')"
                                id="finalidades-delete"
                                name="finalidades-deleteate"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_finality-delete')">Excluir</b>
                            </soft-checkbox>
                        </div> -->
                        <!-- end finalidades -->
                        
                    </div>
                    <hr>
                    <div style="text-align: right;">
                        <a href="#" class="btn btn-success"
                        @click="create">
                            Salvar
                        </a>
                        <a href="#" class="btn btn-default" @click="close">
                            Cancelar
                        </a>
                    </div>
                  </form>
                </div> 
              </div>
    </div>
</template>
<style>
    .modal-container {
        z-index: 93949349394349;
    }
    .modal-item {
        width: 50%;
    }
</style>
<script>
    import SoftInput from "@/components/SoftInput.vue";
    import {closeModal} from "jenesius-vue-modal"
    import PerfisDataService from "../../services/PerfisDataService";
    import SoftCheckbox from "@/components/SoftCheckbox.vue";

    export default {
        data() {
            return {
                userAllPermissions: false,
                undLotacaoAllPermissions: false,
                profilesAllPermissions: false,
                licenseAllPermissions: false,
                licenseFormAllPermissions: false,
                habilitarTodosUsuarios : false,
                profile: {
                    isTrainee : false,
                    name : "",
                    permissions:  [],
                } 
            }
        },
        props: {
            title: String
        },
        components: {
            SoftInput,
            SoftCheckbox,
        },
        methods: {
            addOrRemovePermission(permissionParam) {
                let pos = this.profile.permissions.indexOf(permissionParam)
                if (pos == -1){
                    this.profile.permissions.push(permissionParam);
                } else {
                    this.profile.permissions.splice(pos, 1);
                }
            },
            addOrRemoveAllPermissions(permissionParam) {
                let pos = this.profile.permissions.indexOf(permissionParam+"-index");
                if (pos == -1){
                    switch (permissionParam) {
                        case "user":
                            this.profile.permissions.push("user-index");
                            this.profile.permissions.push("user-store");
                            this.profile.permissions.push("user-update");
                            this.profile.permissions.push("user-deactivate");
                            this.profile.permissions.push("user-activate");

                            console.log(this.profile.permissions);
                            this.userAllPermissions = true;
                            break;
                        
                        case "und_lotacao":
                            this.profile.permissions.push("und_lotacao-index");
                            this.profile.permissions.push("und_lotacao-store");
                            this.profile.permissions.push("und_lotacao-update");
                            this.profile.permissions.push("und_lotacao-deactivate");
                            this.profile.permissions.push("und_lotacao-activate");
                            this.undLotacaoAllPermissions = true;
                            break;

                        case "profile":
                            this.profile.permissions.push("profile-index");
                            this.profile.permissions.push("profile-store");
                            this.profile.permissions.push("profile-update");
                            this.profile.permissions.push("profile-delete");
                            this.profilesAllPermissions = true;
                            break;
                        
                        case "license":
                            this.profile.permissions.push("license-index");
                            this.profile.permissions.push("license-store");
                            this.profile.permissions.push("license-update");
                            this.profile.permissions.push("license-deactivate");
                            this.profile.permissions.push("license-activate");
                            this.profile.permissions.push("field-delete");
                            this.licenseAllPermissions = true;
                            break;
                        
                        case "license_form":
                            this.profile.permissions.push("license_form-index");
                            this.profile.permissions.push("license_form-store");
                            this.profile.permissions.push("license_form-delete");
                            this.profile.permissions.push("license_form-active");
                            this.profile.permissions.push("license_form-inactive");
                            this.profile.permissions.push("license_form-update");
                            this.profile.permissions.push("license_form-request");
                            this.profile.permissions.push("license_form-renew");
                            this.profile.permissions.push("license_form-accept_request");
                            this.licenseFormAllPermissions = true;
                            break;
                        default:
                            break;
                    }
                } else {
                    switch (permissionParam) {
                        case "user":
                            this.profile.permissions.splice(this.profile.permissions.indexOf("user-index"), 1);
                            this.profile.permissions.splice(this.profile.permissions.indexOf("user-store"), 1);
                            this.profile.permissions.splice(this.profile.permissions.indexOf("user-update"), 1);
                            this.profile.permissions.splice(this.profile.permissions.indexOf("user-deactivate"), 1);
                            this.profile.permissions.splice(this.profile.permissions.indexOf("user-activate"), 1);
                            console.log(this.profile.permissions);

                            this.userAllPermissions = false;
                            break;
                        
                        case "und_lotacao":
                            this.profile.permissions.splice(this.profile.permissions.indexOf("und_lotacao-index"), 1);
                            this.profile.permissions.splice(this.profile.permissions.indexOf("und_lotacao-store"), 1);
                            this.profile.permissions.splice(this.profile.permissions.indexOf("und_lotacao-update"), 1);
                            this.profile.permissions.splice(this.profile.permissions.indexOf("und_lotacao-deactivate"), 1);
                            this.profile.permissions.splice(this.profile.permissions.indexOf("und_lotacao-activate"), 1);
                            this.undLotacaoAllPermissions = false;
                            break;

                        case "profile":
                            this.profile.permissions.splice(this.profile.permissions.indexOf("profile-index"), 1);
                            this.profile.permissions.splice(this.profile.permissions.indexOf("profile-store"), 1);
                            this.profile.permissions.splice(this.profile.permissions.indexOf("profile-update"), 1);
                            this.profile.permissions.splice(this.profile.permissions.indexOf("profile-delete"), 1);
                            this.profilesAllPermissions = false;
                            break;
                        
                        case "license":
                            this.profile.permissions.splice(this.profile.permissions.indexOf("license-index"), 1);
                            this.profile.permissions.splice(this.profile.permissions.indexOf("license-store"), 1);
                            this.profile.permissions.splice(this.profile.permissions.indexOf("license-update"), 1);
                            this.profile.permissions.splice(this.profile.permissions.indexOf("license-deactivate"), 1);
                            this.profile.permissions.splice(this.profile.permissions.indexOf("license-activate"), 1);
                            this.profile.permissions.splice(this.profile.permissions.indexOf("field-delete"), 1);
                            this.licenseAllPermissions = false;
                            break;
                        
                        case "license_form":
                            this.profile.permissions.splice(this.profile.permissions.indexOf("license_form-index"), 1);
                            this.profile.permissions.splice(this.profile.permissions.indexOf("license_form-store"), 1);
                            this.profile.permissions.splice(this.profile.permissions.indexOf("license_form-delete"), 1);
                            this.profile.permissions.splice(this.profile.permissions.indexOf("license_form-active"), 1);
                            this.profile.permissions.splice(this.profile.permissions.indexOf("license_form-inactive"), 1);
                            this.profile.permissions.splice(this.profile.permissions.indexOf("license_form-update"), 1);
                            this.profile.permissions.splice(this.profile.permissions.indexOf("license_form-request"), 1);
                            this.profile.permissions.splice(this.profile.permissions.indexOf("license_form-renew"), 1);
                            this.profile.permissions.splice(this.profile.permissions.indexOf("license_form-accept_request"), 1);
                            this.licenseFormAllPermissions = false;
                            break;
                        default:
                            break;
                    }
                }
            },
            close() {
                closeModal();
            },
            create() {
                console.log(this.profile);
                PerfisDataService.create(this.profile)
                .then(() => {
                    this.$emit("create");
                    this.close();
                })
                .catch(e => {
                    console.log(e);
                });
            },
        },
    }
</script>