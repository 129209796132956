<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <h6>Lista de licenças</h6>
    </div>
    <div style="text-align:right; margin-right: 1%; margin-top: -3%;">
      <a href="#"
        class="btn btn-info"
        @click="redirectSignIn"
      >
        <font-awesome-icon icon="fa-solid fa-user-circle" />
        &nbsp; Login
      </a>
      <br>
      <br>
    </div>
    <div style="float:right; margin-left: 10px; margin-right: 10px;"  class="row">
      <div class="col-md-2">
        <label for="">Modelo</label>
        <select v-model="licenca_selecionada" @change="getLicenceTypes" name="licenca" id="licenca" class="form-select form-control">
          <option selected disabled value="">Selecione...</option>
          <option v-for="(licenca, index) in licencas" :key="index" :value='licenca._id'>{{ licenca.name  }}</option>
        </select>
      </div>
      <div class="col-md-2">
        <label for="">Tipo</label>
        <select v-model="tipo_selecionado" @change="handleTypeSelection" name="tipo" id="tipo" class="form-select form-control">
          <option selected disabled value="">Selecione...</option>
          <option v-for="(tipo, index) in tipos" :key="index" :value='tipo._id'>{{ tipo.name  }}</option>

        </select>
      </div>
      <div class="col-md-2">
        <label for="">Subtipo</label>
        <select v-model="subtipo_selecionado" @change="handleSubTypeSelection" name="subtipo" id="subtipo" class="form-select form-control">
          <option selected disabled value="">Selecione...</option>
          <option v-for="(subtipo, index) in subtipos" :key="index" :value='subtipo._id'>{{ subtipo.name  }}</option>
        </select>
      </div>
      <div class="col-md-2">
        <label for="">Finalidade</label>
        <select v-model="finalidade_selecionada" @change="handleFinalitySelection" name="finalidade" id="finalidade" class="form-select form-control">
          <option selected disabled value="">Selecione...</option>
          <option v-for="(finalidade, index) in finalidades" :key="index" :value='finalidade._id'>{{ finalidade.name  }}</option>
        </select>
      </div>
      <div class="col-md-2">
          <label for="">Nome</label>
          <input @keyup="filtrarPorPesquisa" v-model="pesquisa" class="form-control" type="text" placeholder="Pesquisa por nome...">
      </div>
      <div class="col-md-2">
        <label for="" style="color:transparent">.</label>
        <br>
        <a href="#"
        @click="clearFilter"
        style="margin-left:4px;"
        class="btn btn-warning"
      >
        <font-awesome-icon icon="fa-solid fa-undo" />
        &nbsp; Limpar
      </a>
      </div>
    </div>
    <hr>

    <div class="row" style="margin-left:10px">
      <div class="col-md-12">
        <p>{{ count }} licença(s) encontrada(s)</p>

      </div>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nome</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Modelo</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Tipo</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Sub-tipo</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Finalidade</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Expirada</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"> Ações</th>
            </tr>
          </thead>
          <tbody>
            <!-- Fazer v-for no tr -->
            <tr v-for="(licenseform, index) in laravelData.data" :key="index">
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ licenseform.name }}</h6>
                  </div>
                </div>
              </td>
              <!--  -->
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ licenseform.license.name }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ licenseform.type.name }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ licenseform.sub_type.name }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ licenseform.finality.name }}</h6>
                  </div>
                </div>
              </td>
              <!--  -->
              <td v-if="licenseform.expired == false" class="align-middle text-center text-sm">
                <soft-badge color="success" variant="gradient" size="sm"
                  >Não</soft-badge
                >
              </td>
              <td v-else class="align-middle text-center text-sm">
                <soft-badge color="danger" variant="gradient" size="sm"
                  >Sim</soft-badge
                >
              </td>
              <td v-if="licenseform.status == 'active'" class="align-middle text-center text-sm">
                <soft-badge color="success" variant="gradient" size="sm"
                  >Ativa</soft-badge
                >
              </td>
              <td v-else class="align-middle text-center text-sm">
                <soft-badge color="danger" variant="gradient" size="sm"
                  >Inativa</soft-badge
                >
              </td>
              <td class="align-middle" style="text-align:center;">
                <div class="mt-2">
                  <!-- <a
                    href="javascript:;"
                    class="font-weight-bold text-xs btn btn-default"
                    data-toggle="tooltip"
                    data-original-title="Edit user"
                    @click="openShowModal(licenseform._id)"
                    >
                    <font-awesome-icon icon="fa-solid fa-eye" />
                    Visualizar
                    </a
                  >
                  <a
                    href="javascript:;"
                    class="font-weight-bold text-xs btn btn-info"
                    data-toggle="tooltip"
                    data-original-title="Edit user"
                    @click="openEditModal(licenseform._id)"
                    >
                    <font-awesome-icon icon="fa-solid fa-edit" />
                    Editar
                    </a
                  >
                  <a
                    href="javascript:;"
                    class="font-weight-bold text-xs btn btn-warning"
                    data-toggle="tooltip"
                    data-original-title="Edit user"
                    @click="confirmStatusChange(licenseform._id, licenseform.status, index)"
                    >
                    <font-awesome-icon icon="fa-solid fa-sync" />
                    Alterar status
                    </a
                  > -->
                  <a v-if="licenseform.documentId"
                    href="javascript:;"
                    class="font-weight-bold text-xs btn btn-success"
                    data-toggle="tooltip"
                    data-original-title="Delete"
                    @click="downloadAuthorization(licenseform.documentId)"
                    >
                    <font-awesome-icon icon="fa-solid fa-download" />
                    Autorização
                    </a
                  >
                  <a v-else
                    href="javascript:;"
                    class="font-weight-bold text-xs btn btn-secondary"
                    data-toggle="tooltip"
                    data-original-title="Delete"
                    @click="notifyPendingAuthorization()"
                    >
                    <font-awesome-icon icon="fa-solid fa-download" />
                    Autorização
                    </a
                  >
                </div>
              </td>
            </tr>
            <!-- end v-for -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div style="float:right">
    <Pagination :key="page"  v-if="laravelData" :laravelData="laravelData" @changepage="getAll" />
  </div>
</template>

<script>
// import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftBadge from "@/components/SoftBadge.vue";
// import { openModal } from "jenesius-vue-modal";
// import LicencasFormsCreate from "./LicencasFormsCreate.vue";
// import LicencasFormsEdit from "./LicencasFormsEdit.vue";
// import LicencasFormsShow from "./LicencasFormsShow.vue";
// import LicencasFormsRenew from "./LicencasFormsRenew.vue";
// import LicencasFormsDataService from "../../services/LicencasFormsDataService";
// import LicencasDataService from "../../services/LicencasDataService";
// import TiposDataService from "../../services/TiposDataService";
// import SubtiposDataService from "../../services/SubtiposDataService";
// import FinalidadesDataService from "../../services/FinalidadesDataService";
import LicencasFormsExternalDataService from "../../services/LicencasFormsExternalDataService";
import { useToast } from "vue-toastification";
import Pagination from "./Pagination.vue";
import router from "@/router";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: "licenseforms-table",
  data() {
    return {
      laravelData: {
          type:Object,
      },
      // filtro modelo
      paramFieldsAux: "",
      reports: "",
      campos_selecionados: "",
      licencas: [],
      licenca_selecionada: "",
      tipos: [],
      tipo_selecionado: "",
      tipo_fields: "",
      subtipos: [],
      subtipo_selecionado: "",
      subtipo_fields: "",
      finalidades: [],
      finalidade_selecionada: "",
      finalidade_fields: "",
      count: 0,
      // filtro modelo end
      page: 0,
      pesquisa: "",
    };
  },
  methods: {
      getAll(page) {
        let params = {page: page};
        
        if(this.pesquisa != ""){params.name = this.pesquisa};
        if(this.licenca_selecionada != ""){params.licenseId = this.licenca_selecionada};
        if(this.tipo_selecionado != ""){params.typeId = this.tipo_selecionado};
        if(this.subtipo_selecionado != ""){params.subTypeId = this.subtipo_selecionado};
        if(this.finalidade_selecionada != ""){params.finalityId = this.finalidade_selecionada};
        console.log(params);
        // console.log(params);
        LicencasFormsExternalDataService.getAllLicenseForms(params)
        .then(response => {
          this.laravelData = response.data.licenseForms;
          this.page = page;
          this.count = response.data.count;
        })
        .catch(e => {
          console.log(e);
        });
      },
      async redirectSignIn(){
        await router.push("/sign-in");
      },
      clearFilter() {
        this.licenca_selecionada    = "";
        this.tipo_selecionado     = "";
        this.subtipo_selecionado  = "";
        this.finalidade_selecionada = "";
        this.pesquisa = "";
        this.tipos = "";
        this.subtipos = "";
        this.finalidades = "";
        this.getAll();
      },
      // activate(id) {
      //   LicencasFormsDataService.activate(id);
      // },
      // deactivate(id) {
      //   LicencasFormsDataService.deactivate(id);
      // },
      filtrarPorPesquisa(){
        this.getAll();
      },  
      // async openCreateModal() {
      //   const modal = await openModal(LicencasFormsCreate, {});

      //   modal.on('create', () => {
      //       this.toast.success("Licença criada com sucesso!", {
      //         timeout: 6000
      //       });
      //       this.getAll(this.params);
      //   })
      // },
      // async openEditModal(_id) {
      //   const modal = await openModal(LicencasFormsEdit, {idformlicenca: _id});

      //   modal.on('update', () => {
      //       this.toast.success("Licença editada com sucesso!", {
      //         timeout: 6000
      //       });
      //       this.getAll(this.params);
      //   })
      // },
      // openShowModal(_id) {
      //   openModal(LicencasFormsShow, {idformlicenca: _id});
      // },
      // async openRenewModal(_id) {
      //   const modal = await openModal(LicencasFormsRenew, {idformlicenca: _id});

      //   modal.on('renew', () => {
      //       this.toast.success("Licença renovada com sucesso!", {
      //         timeout: 6000
      //       });
      //       this.getAll(this.params);
      //   })
      // },
      // remove(_id, index) {
      //   // Use sweetalert2
      //   this.$swal({
      //     title: 'Você tem certeza?',
      //     text: "Deseja mesmo excluir esta licença?",
      //     icon: 'warning',
      //     showCancelButton: true,
      //     confirmButtonText: 'Sim, desejo!',
      //     cancelButtonText: 'Não, cancelar!',
      //     reverseButtons: true
      //   }).then((result) => {
      //     if (result.isConfirmed) {
      //       this.toast.success("Licença excluída com sucesso!", {
      //         timeout: 6000
      //       });
      //       LicencasFormsDataService.delete(_id);
      //       this.laravelData.splice(index, 1);
      //       this.$swal(
      //         'Licença excluída!',
      //         'A licença foi excluída com sucesso.',
      //         'success'
      //       )
      //     } else if (
      //       /* Read more about handling dismissals below */
      //       result.dismiss === this.$swal.DismissReason.cancel
      //     ) {
      //       this.$swal(
      //         'Ação cancelada',
      //         'A exclusão foi cancelada.',
      //         'error'
      //       )
      //     }
      //   })
      // },
    //   redirectSolicitacoes() {
    //     this.$router.push("/solicitacoes");
    //   },
    //   confirmStatusChange(id, status, index) {
    //   // Use sweetalert2
    //   this.$swal({
    //     title: 'Você tem certeza?',
    //     text: "Deseja mesmo alterar o status desta Licença?",
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonText: 'Sim, desejo!',
    //     cancelButtonText: 'Não, cancelar!',
    //     reverseButtons: true
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       this.toast.success("Status alterado com sucesso!", {
    //         timeout: 6000
    //       });
    //       if(status == "active"){
    //         this.deactivate(id);
    //         this.laravelData.data[index].status = "inactive";
    //       } else {
    //         this.activate(id);
    //         this.laravelData.data[index].status = "active";
    //       }
    //       this.$swal(
    //         'Status alterado!',
    //         'O status da Licença foi alterado com sucesso.',
    //         'success'
    //       )
    //     } else if (
    //       /* Read more about handling dismissals below */
    //       result.dismiss === this.$swal.DismissReason.cancel
    //     ) {
    //       this.$swal(
    //         'Ação cancelada',
    //         'A alteração de status foi cancelada.',
    //         'error'
    //       )
    //     }
    //   })
    // },
    // Handles
    notifyPendingAuthorization(){
      this.$swal({
          title: 'Ops...',
          text: "A licença selecionada ainda não possui documento de autorização.",
          icon: 'warning',
          // showCancelButton: true,
          // confirmButtonText: 'Sim, desejo!',
          // cancelButtonText: 'Não, cancelar!',
          // reverseButtons: true
        })    
      },  
    downloadAuthorization(id){
      LicencasFormsExternalDataService.downloadAuthorizationDocument(id)
      .then(response => {
        window.open(response.data, "_blank")
      })
      .catch(e => {
          console.log(e);
      });
    },
    getAllLicenses() {
          LicencasFormsExternalDataService.getAllLicenses({"active": true})
          .then(response => {
              console.log(response);
              this.licencas = response.data.license.data;
          })
          .catch(e => {
              console.log(e);
          });
      },
      getLicenceTypes() {
          this.tipo_selecionado = "";       
          this.subtipo_selecionado = "";                        
          this.finalidade_selecionada = "";                    

          LicencasFormsExternalDataService.showLicense(this.licenca_selecionada)
          .then(response => {
              this.tipos = response.data.types;
              this.getAll();
          })
          .catch(e => {
              console.log(e);
          });
      },
      handleTypeSelection() {
          this.subtipo_selecionado = "";                        
          this.finalidade_selecionada = "";   

          LicencasFormsExternalDataService.showType(this.tipo_selecionado, {fieldRequired: true})
          .then((response) => {
              console.log(response);
              this.tipo_fields = response.data.fields;
              this.subtipos = response.data.subTypes;
              this.getAll();
          }).catch(e => {
              console.log(e)
          });
      },  
      handleSubTypeSelection() {
          this.finalidade_selecionada = "";   
                  
          LicencasFormsExternalDataService.showSubType(this.subtipo_selecionado, {fieldRequired: true})
          .then((response) => {
              this.subtipo_fields = response.data.fields;
              this.finalidades = response.data.finalities;
              this.getAll();
          }).catch(e => {
              console.log(e)
          });
      },
      handleFinalitySelection() {
          LicencasFormsExternalDataService.showFinality(this.finalidade_selecionada, {fieldRequired: true})
          .then((response) => {
              this.finalidade_fields = response.data.fields;
              this.getAll();
          }).catch(e => {
              console.log(e)
          });
      },
    // Handles end
  },
  components: {
    // SoftAvatar,
    SoftBadge,
    Pagination,
  },
  mounted (){
    this.getAllLicenses(this.params);
    this.getAll(1);
  }
};
</script>
