<template>
    <div class="crud-modal">
        <div class="card card-plain">
            <div class="pb-0 card-header text-start"> 
                <h3 class="font-weight-bolder">
                Adicionar campos
                </h3>
                <p class="mb-0">Clique para adicionar os campos abaixo</p>
            </div>
            <div class="card-body">
                <h4>Campos de tipo</h4>
                <div class="row">
                    <div class="col-md-3" v-for="(field, index) in tipo_fields" :key="index">
                        <soft-checkbox
                            :id="field.id"
                            name="user-store"
                            class="font-weight-light"
                            @click="addOrRemoveField(field._id)"
                        >
                            <b @click="addOrRemoveField(field)">{{ field.title }}</b>
                        </soft-checkbox>
                    </div>
                </div>
                <hr>
                <h4>Campos de sub-tipo</h4>
                <div class="row">
                    <div class="col-md-3" v-for="(field, index) in subtipo_fields" :key="index">
                        <soft-checkbox
                            :id="field.id"
                            name="user-store"
                            class="font-weight-light"
                            @click="addOrRemoveField(field._id)"
                        >
                            <b @click="addOrRemoveField(field)">{{ field.title }}</b>
                        </soft-checkbox>
                    </div>
                </div>
                <hr>
                <h4>Campos de finalidade</h4>
                <div class="row">
                    <div class="col-md-3" v-for="(field, index) in finalidade_fields" :key="index">
                        <soft-checkbox
                            :id="field.id"
                            name="user-store"
                            class="font-weight-light"
                            @click="addOrRemoveField(field._id)"
                        >
                            <b @click="addOrRemoveField(field)">{{ field.title }}</b>
                        </soft-checkbox>
                    </div>
                </div>
                <hr>
                <div style="text-align: right;">
                    <a href="#" class="btn btn-success"
                    @click="submit">
                        Finalizar
                    </a>
                    <a href="#" class="btn btn-default" @click="close">
                        Cancelar
                    </a>
                </div>
            </div> 
        </div>
    </div>
</template>
<style>
    .modal-container {
        z-index: 93949349394349;
    }
    .modal-item {
        width: 50%;
    }
</style>
<script>
    import {closeModal} from "jenesius-vue-modal"
    import SoftCheckbox from "@/components/SoftCheckbox.vue";

    export default {
        data() {
            return {
                campos_selecionados: [],
            }
        },
        props: {
            tipo_fields: Object,
            subtipo_fields: Object,
            finalidade_fields: Object
        },
        components: {
            SoftCheckbox
        },
        methods: {
            close() {
                closeModal();
            },
            submit() {
                this.$emit("update-fields", this.campos_selecionados);
                this.close();
            },
            addOrRemoveField(fieldParam) {
                console.log(fieldParam)
                let pos = this.campos_selecionados.indexOf(fieldParam)
                if (pos == -1){
                    this.campos_selecionados.push(fieldParam);
                } else {
                    this.campos_selecionados.splice(pos, 1);
                }
            },
        },
        mounted() {
            // console.log(this.tipo_fields);
            // console.log(this.subtipo_fields);
            // console.log(this.finalidade_fields);
        }
    }
</script>