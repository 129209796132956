<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <relatorios-table />
      </div>
    </div>
    
  </div>
</template>

<script>
import RelatoriosTable from "./components/RelatoriosTable.vue";

export default {
  name: "relatorios",
  components: {
    RelatoriosTable,
  },
};
</script>
