<template>
    <div class="crud-modal">
        <div class="card card-plain">

                <div class="pb-0 card-header text-start"> 
                  <h3 class="font-weight-bolder">
                    Upload de documento de autorização
                  </h3>
                  <p class="mb-0">Insira os dados abaixo.</p>
                </div>
                <div class="card-body">
                  <form role="form" class="text-start">
                    <div class="row">
                        <!-- <FileUpload :maxSize="30" accept="pdf"/> -->
                        <input
                            type="file"
                            @change="onFileChanged($event)"
                            />
                        </div>
                  </form>
                  <hr style="margin-top: 50px;">
                    <div style="text-align: right;">
                        <a href="#" class="btn btn-success" @click="uploadDocument">
                            Salvar
                        </a>
                        <a href="#" class="btn btn-default" @click="close">
                            Cancelar
                        </a>
                    </div>
                </div> 
              </div>
    </div>
</template>
<style>
    .modal-container {
        z-index: 93949349394349;
    }
    .modal-item {
        width: 50%;
    }
</style>
<style lang="scss">
  .dropbox {
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    background: lightcyan;
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
  }
  
  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }
  
  .dropbox:hover {
    background: lightblue; /* when mouse over to the drop zone, change color */
  }
  
  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }
</style>
<script>
    // import SoftInput from "@/components/SoftInput.vue";
    import {closeModal} from "jenesius-vue-modal"
    // import FileUpload from "../components/FileUpload.vue";
    import LicencasFormsDataService from "../../services/LicencasFormsDataService";

    export default {
        data() {
            return {
                // licenca: "",
                variavelteste: "",
                dueDate: "",
                file: "",
            }
        },
        props: {
            idformlicenca: String
        },
        methods: {
            renew() {
                console.log(this.dueDate);
                LicencasFormsDataService.renew(this.idformlicenca, {"dueDate": this.dueDate})
                .then(response => {
                    console.log(response);
                    if(response.data.validation_error){
                        return;
                    } else {
                        this.$emit("renew");
                        this.close();
                    }  
                })
                .catch(e => {
                    console.log(e);
                });
            },
            onFileChanged($event) {
                const target = $event.target;
                if (target && target.files) {
                    this.file = target.files[0];
                }
            },
            async uploadDocument() {
                if (this.file) {
                    try {

                        let formData = new FormData();
                        formData.append("document", this.file);
                        formData.append("licenseFormId", this.idformlicenca);

                        LicencasFormsDataService.uploadAuthorizationDocument(formData)
                        .then(response => {
                            console.log(response);
                            this.$emit("uploaded");
                            this.close();
                        })
                        .catch(e => {
                            console.log(e);
                        });
                    // save file.value
                    } catch (error) {
                        console.error(error);
                        this.file = null;
                    }
                }
            },
            // fetchLicenca() {
            //     LicencasFormsDataService.show(this.idformlicenca)
            //     .then(response => {
            //         console.log(response);
            //         this.licenca = response.data.licenseForm;
            //     })
            //     .catch(e => {
            //         console.log(e);
            //     });
            // },
            close() {
                closeModal();
            }
        },
        components: {
            // SoftInput,
            // FileUpload
        },
        mounted() {
            // this.fetchLicenca();
        }
    }
</script>