<template>
    <div class="crud-modal">
        <div class="card card-plain">

                <div class="pb-0 card-header text-start"> 
                  <h3 class="font-weight-bolder">
                    Editar perfil
                  </h3>
                  <p class="mb-0">Ajuste os dados abaixo.</p>
                </div>
                <div class="card-body">
                  <form role="form" class="text-start">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Nome</label>
                            <soft-input
                            id="Nome"
                            type="check"
                            placeholder="Insira o nome..."
                            name="Nome"
                            v-model:value="perfil.name"
                            />
                        </div>
                        <div class="col-md-6">
                            <label>Estagiário</label>
                            <select class="form-control form-select" name="isTrainee" v-model="perfil.isTrainee" id="">
                                <option disabled value="">Selecione...</option>
                                <option value=false>Não</option>
                                <option value=true>Sim</option>
                            </select>
                        </div>
                    </div>
                    <hr>
                    <div class="row" style="overflow:auto;">
                        <div class="col-md-2">
                            <h4>Usuários</h4>
                            <soft-checkbox
                                @click="addOrRemovePermission('user-index')"
                                id="user-index"
                                name="user-index"
                                :checked="hasPermission('user-index')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('user-index')">Ver listagem</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('user-store')"
                                id="user-store"
                                name="user-store"
                                :checked="hasPermission('user-store')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('user-store')">Cadastrar</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('user-update')"
                                id="user-update"
                                name="user-update"
                                :checked="hasPermission('user-update')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('user-update')">Editar</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('user-deactivate')"
                                id="user-deactivate"
                                name="user-deactivate"
                                :checked="hasPermission('user-deactivate')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('user-deactivate')">Desativar</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('user-activate')"
                                id="user-activate"
                                name="user-activate"
                                :checked="hasPermission('user-activate')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('user-activate')">Ativar</b>
                            </soft-checkbox>
                        </div>
                        <!-- end usuarios -->
                        <!-- start unidades de lotação -->
                        <div class="col-md-2">
                            <h4>Unidades de lotação</h4>
                            <soft-checkbox
                                @click="addOrRemovePermission('und_lotacao-index')"
                                id="und_lotacao-index"
                                name="und_lotacao-index"
                                :checked="hasPermission('und_lotacao-index')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('und_lotacao-index')">Ver listagem</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('und_lotacao-store')"
                                id="und_lotacao-store"
                                name="und_lotacao-store"
                                :checked="hasPermission('und_lotacao-store')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('und_lotacao-store')">Cadastrar</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('und_lotacao-update')"
                                id="und_lotacao-update"
                                name="und_lotacao-update"
                                :checked="hasPermission('und_lotacao-update')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('und_lotacao-update')">Editar</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('und_lotacao-deactivate')"
                                id="und_lotacao-deactivate"
                                name="und_lotacao-deactivate"
                                :checked="hasPermission('und_lotacao-deactivate')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('und_lotacao-deactivate')">Desativar</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('und_lotacao-activate')"
                                id="und_lotacao-activate"
                                name="und_lotacao-activate"
                                :checked="hasPermission('und_lotacao-activate')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('und_lotacao-activate')">Ativar</b>
                            </soft-checkbox>
                        </div>
                        <!-- end unidades de lotação -->
                        <!-- start profiles -->
                        <div class="col-md-2">
                            <h4>Perfis / Permissões</h4>
                            <soft-checkbox
                                @click="addOrRemovePermission('profile-index')"
                                id="profile-index"
                                name="profile-index"
                                :checked="hasPermission('profile-index')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('profile-index')">Ver listagem</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('profile-store')"
                                id="profile-store"
                                name="profile-store"
                                :checked="hasPermission('profile-store')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('profile-store')">Cadastrar</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('profile-update')"
                                id="profile-update"
                                name="profile-update"
                                :checked="hasPermission('profile-update')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('profile-update')">Editar</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('profile-delete')"
                                id="profile-delete"
                                name="profile-deleteate"
                                :checked="hasPermission('profile-delete')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('profile-delete')">Excluir</b>
                            </soft-checkbox>
                        </div>
                        <!-- end profiles -->
                        <!-- modelos -->
                        <div class="col-md-2">
                            <h4>Modelos de licença</h4>
                            <soft-checkbox
                                @click="addOrRemovePermission('license-index')"
                                id="modelos-index"
                                name="modelos-index"
                                :checked="hasPermission('license-index')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license-index')">Ver listagem</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license-store')"
                                id="modelos-store"
                                name="modelos-store"
                                :checked="hasPermission('license-store')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license-store')">Cadastrar</b>
                            </soft-checkbox>
                            <!-- <soft-checkbox
                                @click="addOrRemovePermission('license-show')"
                                id="modelos-show"
                                name="modelos-show"
                                :checked="hasPermission('license-show')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license-show')">Ver detalhes</b>
                            </soft-checkbox> -->
                            <soft-checkbox
                                @click="addOrRemovePermission('license-update')"
                                id="modelos-update"
                                name="modelos-update"
                                :checked="hasPermission('license-update')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license-update')">Editar</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license-deactivate')"
                                id="modelos-deactivate"
                                name="modelos-deactivate"
                                :checked="hasPermission('license-deactivate')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license-deactivate')">Desativar</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license-activate')"
                                id="modelos-activate"
                                name="modelos-activate"
                                :checked="hasPermission('license-activate')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license-activate')">Ativar</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('field-delete')"
                                id="modelos-delete-fields"
                                name="modelos-delete-fields"
                                :checked="hasPermission('field-delete')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('field-delete')">Excluir campos</b>
                            </soft-checkbox>
                        </div>
                        <!-- end modelos -->
                        <!-- licencas -->
                        <div class="col-md-2">
                            <h4>Licenças (formulário)</h4>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_form-index')"
                                id="licenceforms-index"
                                name="licenceforms-index"
                                :checked="hasPermission('license_form-index')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_form-index')">Ver listagem</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_form-store')"
                                id="licenceforms-store"
                                name="licenceforms-store"
                                :checked="hasPermission('license_form-store')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_form-store')">Cadastrar</b>
                            </soft-checkbox>
                            <!-- <soft-checkbox
                                @click="addOrRemovePermission('license_form-show')"
                                id="licenceforms-show"
                                name="licenceforms-show"
                                :checked="hasPermission('license_form-show')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_form-show')">Ver detalhes</b>
                            </soft-checkbox> -->
                            <soft-checkbox
                                @click="addOrRemovePermission('license_form-delete')"
                                id="licenceforms-delete"
                                name="licenceforms-deleteate"
                                :checked="hasPermission('license_form-delete')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_form-delete')">Excluir</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_form-active')"
                                id="licenceforms-active"
                                name="licenceforms-active"
                                :checked="hasPermission('license_form-active')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_form-active')">Habilitar</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_form-inactive')"
                                id="licenceforms-inactive"
                                name="licenceforms-inactive"
                                :checked="hasPermission('license_form-inactive')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_form-inactive')">Desabilitar</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_form-update')"
                                id="licenceforms-edit"
                                name="licenceforms-edit"
                                :checked="hasPermission('license_form-update')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_form-update')">Editar</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_form-request')"
                                id="licenceforms-requests"
                                name="licenceforms-requests"
                                :checked="hasPermission('license_form-request')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_form-request')">Ver solicitações</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_form-renew')"
                                id="licenceforms-renew"
                                name="licenceforms-renew"
                                :checked="hasPermission('license_form-renew')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_form-renew')">Renovar</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_form-accept_request')"
                                id="licenceforms-acceptrequest"
                                name="licenceforms-acceptrequest"
                                :checked="hasPermission('license_form-accept_request')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_form-accept_request')">Aprovar solicitação</b>
                            </soft-checkbox>
                        </div>
                        <!-- end licencas -->
                    </div>
                    <div class="row">
                        <!-- tipos -->
                        <!-- <div class="col-md-2">
                            <h4>Modelos de tipo</h4>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_type-index')"
                                id="tipos-index"
                                name="tipos-index"
                                :checked="hasPermission('license_type-index')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_type-index')">Ver listagem</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_type-store')"
                                id="tipos-store"
                                name="tipos-store"
                                :checked="hasPermission('license_type-store')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_type-store')">Cadastrar</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_type-show')"
                                id="tipos-show"
                                name="tipos-show"
                                :checked="hasPermission('license_type-show')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_type-show')">Ver detalhes</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_type-update')"
                                id="tipos-update"
                                name="tipos-update"
                                :checked="hasPermission('license_type-update')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_type-update')">Editar</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_type-delete')"
                                id="tipos-delete"
                                name="tipos-deleteate"
                                :checked="hasPermission('license_type-delete')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_type-delete')">Excluir</b>
                            </soft-checkbox>
                        </div> -->
                        <!-- end tipos -->
                        <!-- subtipos -->
                        <!-- <div class="col-md-2">
                            <h4>Modelos de sub-tipo</h4>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_sub_type-index')"
                                id="subtipos-index"
                                name="subtipos-index"
                                :checked="hasPermission('license_sub_type-index')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_sub_type-index')">Ver listagem</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_sub_type-store')"
                                id="subtipos-store"
                                name="subtipos-store"
                                :checked="hasPermission('license_sub_type-store')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_sub_type-store')">Cadastrar</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_sub_type-show')"
                                id="subtipos-show"
                                name="subtipos-show"
                                :checked="hasPermission('license_sub_type-show')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_sub_type-show')">Ver detalhes</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_sub_type-update')"
                                id="subtipos-update"
                                name="subtipos-update"
                                :checked="hasPermission('license_sub_type-update')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_sub_type-update')">Editar</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_sub_type-delete')"
                                id="subtipos-delete"
                                name="subtipos-deleteate"
                                :checked="hasPermission('license_sub_type-delete')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_sub_type-delete')">Excluir</b>
                            </soft-checkbox>
                        </div> -->
                        <!-- end subtipos -->
                        <!-- finalidades -->
                        <!-- <div class="col-md-2">
                            <h4>Modelos de finalidade</h4>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_finality-index')"
                                id="finalidades-index"
                                name="finalidades-index"
                                :checked="hasPermission('license_finality-index')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_finality-index')">Ver listagem</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_finality-store')"
                                id="finalidades-store"
                                name="finalidades-store"
                                :checked="hasPermission('license_finality-store')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_finality-store')">Cadastrar</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_finality-show')"
                                id="finalidades-show"
                                name="finalidades-show"
                                :checked="hasPermission('license_finality-show')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_finality-show')">Ver detalhes</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_finality-update')"
                                id="finalidades-update"
                                name="finalidades-update"
                                :checked="hasPermission('license_finality-update')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_finality-update')">Editar</b>
                            </soft-checkbox>
                            <soft-checkbox
                                @click="addOrRemovePermission('license_finality-delete')"
                                id="finalidades-delete"
                                name="finalidades-deleteate"
                                :checked="hasPermission('license_finality-delete')"
                                class="font-weight-light"
                            >
                                <b @click="addOrRemovePermission('license_finality-delete')">Excluir</b>
                            </soft-checkbox>
                        </div> -->
                        <!-- end finalidades -->
                    </div>
                    <hr>
                    <div style="text-align: right;">
                        <a href="#" class="btn btn-success"
                        @click="update">
                            Salvar
                        </a>
                        <a href="#" class="btn btn-default" @click="close">
                            Cancelar
                        </a>
                    </div>
                  </form>
                </div> 
              </div>
    </div>
</template>
<style>
    .modal-container {
        z-index: 93949349394349;
    }
    .modal-item {
        width: 50%;
    }
</style>
<script>
    import SoftInput from "@/components/SoftInput.vue";
    import {closeModal} from "jenesius-vue-modal"
    import PerfisDataService from "../../services/PerfisDataService";
    import SoftCheckbox from "@/components/SoftCheckbox.vue";

    export default {
        data() {
            return {
                perfil: "", 
            }
        },
        props: {
            idperfil: String
        },
        components: {
            SoftInput,
            SoftCheckbox,
        },
        methods: {
            addOrRemovePermission(permissionParam) {
                let pos = this.perfil.permissions.indexOf(permissionParam)

                if (pos == -1){
                    this.perfil.permissions.push(permissionParam);
                } else {
                    this.perfil.permissions.splice(pos, 1);
                }
            },
            update() {
                PerfisDataService.update(this.idperfil, this.perfil)
                .then(response => {
                    if(response.data.validation_error){
                        return;
                    } else {
                        this.$emit("update");
                        this.close();
                    }  
                })
                .catch(e => {
                    console.log(e);
                });
            },
            fetchPerfil() {
                PerfisDataService.show(this.idperfil)
                .then(response => {
                    this.perfil = response.data.profile;
                })
                .catch(e => {
                    console.log(e);
                });
            },
            hasPermission(permission) {
                // Se não tiver nenhuma
                if(this.perfil.permissions == '') {
                    return false;
                }

                // Se não, verificar
                for (const key in this.perfil.permissions) {
                    if (Object.hasOwnProperty.call(this.perfil.permissions, key)) {
                        const element = this.perfil.permissions[key];
                        
                        if (element == permission) {
                            return true;
                        }
                    }
                }

                return false;
            },
            close() {
                closeModal();
            },
            create() {
                PerfisDataService.create(this.profile)
                .then(() => {
                    this.$emit("create");
                    this.close();
                })
                .catch(e => {
                    console.log(e);
                });
            },
        },
        mounted() {
            this.fetchPerfil();
        }
    }
</script>