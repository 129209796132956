import axios from "axios";
import { useToast } from "vue-toastification";
import router from "./router";
// import AuthDataService from "./services/AuthDataService";

const toast = useToast();
const url = process.env.VUE_APP_API_URL;
const frontUrl = process.env.VUE_APP_FRONT_URL;

const axiosIntercept = axios.create({
  baseURL: url + "/api",
  headers: {
    "Content-type": "application/json",
    "Authorization": "Bearer " + localStorage.getItem("token")
  }
}); 

const requestHandler = request => {
  request.headers.Authorization = 'Bearer ' + localStorage.getItem("token");
  return request;
};

const responseHandler = response => {
  if (response.status === 401) {
    window.location = frontUrl + '/sign-in';

      toast.warning("Sessão expirada! Por favor insira seus dados novamente.</p>", {
        timeout: 6000
      });
  }

  if (response.status === 200 && response.data.validation_error) {
    let mensagem = response.data.mensagem;
    
    toast.error(mensagem, {
      timeout: 8000
    });
    
  } else {
    // AuthDataService.refresh()
    // .then((data) => {
        // localStorage.setItem("token", data.data.acess_token)
    // })
    // .catch(e => {
        // console.log(e);
    // });

  }

  return response;
};

const errorHandler = async error => {
  if (error.response.status === 401) { 
    
      await router.push("/sign-in");
      toast.warning("Sessão expirada! Por favor insira seus dados novamente.", {
        timeout: 8000
      });
  }

  if(error.response.status === 404) { 
    toast.error("Página não encontrada! Verifique se a url está correta.", {
      timeout: 8000
    });
  }

  if (error.response.status === 422) {
    let errors = error.response.data.errors;

    if(!errors) {
      toast.error(error.response.data.message, {
        timeout: 8000
      });

      return Promise.reject(error);
    }
    
    for (const key in errors) {
      if (Object.hasOwnProperty.call(errors, key)) {
        const element = errors[key];
        element.forEach(err => {
          toast.error(err, {
            timeout: 8000
          });
        });
      }
    }
  }
  return Promise.reject(error);
};


axiosIntercept.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

axiosIntercept.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default axiosIntercept;
