<template>
    <div class="crud-modal">
        <div class="card card-plain">

                <div class="pb-0 card-header text-start"> 
                  <h3 class="font-weight-bolder">
                    Detalhes de modelo de finalidade
                  </h3>
                </div>
                <div class="card-body">
                  <form role="form" class="text-start">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Nome</label>
                            <p>{{ finalidade.name }}</p>
                        </div>
                        <div class="col-md-6">
                            <!-- <label>Senha</label>
                            <soft-input
                            id="password"
                            type="password"
                            placeholder="Senha"
                            name="password"
                            /> -->
                            <!-- <label for="">Status</label> -->
                            <!-- <select v-model="unidade.isActive" name="status" id="status" class="form-select form-control"> -->
                                <!-- <option selected value=true>Habilitada</option> -->
                                <!-- <option value=false>Desabilitada</option> -->
                            <!-- </select> -->
                        </div>
                    </div>
                    <hr>
                    <h4>Campos</h4>
                    <div class="table-responsive p-0 ">
                        <table class="table align-items-center mb-0">
                            <thead>
                                <tr>
                                    <th style="text-align:center" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Tipo</th>
                                    <th style="text-align:center" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Ordem</th>
                                    <th style="text-align:center" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Título</th>
                                    <!-- <th style="text-align:center" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Obrigatório</th> -->
                                    <th style="text-align:center" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Texto de ajuda</th>
                                    <th style="text-align:center" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Texto interno</th>
                                    <th style="text-align:center" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Máscara</th>
                                    <!-- <th style="text-align:center" class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Validador</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(content, index) in tableRows" :key="index">
                                    <td style="text-align:center;">
                                        <p v-if="content.fieldType == 'text'">Texto</p>
                                        <p v-if="content.fieldType == 'number'">Número</p>
                                        <p v-if="content.fieldType == 'date'">Data</p>
                                        <p v-if="content.fieldType == 'textarea'">Área</p>
                                        <p v-if="content.fieldType == 'phone'">Telefone</p>
                                        <p v-if="content.fieldType == 'file'">Arquivo</p>
                                    </td>
                                    <td style="text-align:center;">
                                       <p>{{ content.order }}</p>
                                    </td>
                                    <td style="text-align:center;">
                                        <p>{{ content.title }}</p>
                                    </td>
                                    <!-- <td style="text-align:center;">
                                        <p v-if="content.required">Sim</p>
                                        <p v-else>Não</p>
                                    </td> -->
                                    <td style="text-align:center;">
                                        <p>{{ content.helpText }}</p>
                                    </td>
                                    <td style="text-align:center;">
                                        <p>{{ content.innerText }}</p>
                                    </td>
                                    <td style="text-align:center;">
                                        <p>{{ content.mask }}</p>
                                    </td>
                                    <!-- <td style="text-align:center;">
                                        <p>{{ content.validator }}</p>
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <hr>
                    <div style="text-align: right;">
                        <a href="#" class="btn btn-default" @click="close">
                            Fechar
                        </a>
                    </div>
                  </form>
                </div> 
              </div>
    </div>
</template>
<style>
    .modal-container {
        z-index: 93949349394349;
    }
    .modal-item {
        width: 50%;
    }
</style>
<script>
    import {closeModal} from "jenesius-vue-modal"
    import FinalidadesDataService from "../../services/FinalidadesDataService";

    export default {
        data() {
            return {
               finalidade:  {
                "id" : "",
                "name" : "",
                "finalidadeid": "",
                // "isActive" : true,
               },
               tableRows: [{
                    "fieldType": "",
                    "order": "",
                    "title": "",
                    "required": "",
                    "helpText": "",
                    "innerText": "",
                    "mask": "",
                    "validator": "", 
                }],
               fields: {},
            }
        },
        props: {
            finalidadeid: String
        },
        components: {
        },
        methods: {
            close() {
                closeModal();
            },
            getAllChildren() {
                FinalidadesDataService.show(this.finalidade._id)
                .then(response => {
                    this.tableRows = response.data.fields;
                    this.subtipo = response.data.licenseType;
                })
                .catch(e => {
                    console.log(e);
                });
            },
            addTableRow() {
                let fieldObject = {
                    "fieldType": "",
                    "order": "",
                    "title": "",
                    "required": "",
                    "helpText": "",
                    "innerText": "",
                    "mask": "",
                    "validator": "", 
                };
                this.tableRows.push(fieldObject);
            },
            deleteTableRow(index) {
                this.tableRows.splice(index, 1);
            },
            update() {
                let model = {
                    name : this.finalidade.name,
                    fields : this.tableRows,
                }
                console.log(model);
                FinalidadesDataService.update(this.finalidade._id, model)
                .then(() => {
                    this.$emit("update");
                    this.close();
                })
                .catch(e => {
                    console.log(e);
                });
            },
        },
        mounted() {
            console.log(this.finalidadeid);
            this.finalidade._id = this.finalidadeid;


            this.getAllChildren();
        }
    }
</script>