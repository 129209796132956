import http from "../http-common";

// //license_form
// Route::post('license_form/store', [LicenseFormController::class, 'store'])->middleware(['permission:license_form-store']);
// Route::get('license_form/index', [LicenseFormController::class, 'index'])->middleware(['permission:license_form-index']);
// Route::get('license_form/show/{id}', [LicenseFormController::class, 'show'])->middleware(['permission:license_form-show']);
// Route::put('license_form/active/{id}', [LicenseFormController::class, 'active'])->middleware('permission:license_form-active');
// Route::put('license_form/inactive/{id}', [LicenseFormController::class, 'inactive'])->middleware('permission:license_form-inactive');
// Route::put('license_form/update/{id}', [LicenseFormController::class, 'update'])->middleware('permission:license_form-update');
// Route::get('license_form/indexRequests', [LicenseFormController::class, 'indexLicenseFormRequests'])->middleware(['permission:license_form-request']);
// Route::put('license_form/active/{id}', [LicenseFormController::class, 'setStatusActive'])->middleware(['permission:license_form-status']);
// Route::put('license_form/inactive/{id}', [LicenseFormController::class, 'setStatusInactive'])->middleware(['permission:license_form-status']);

// //});

// //Dashboard
// Route::get('dashboard/count', [DashboardController::class, 'licenseCountData']);
// Route::get('dashboard/data/{id}', [DashboardController::class, 'licenseFormData']);

// Route::get('refreshAuth', [AuthController::class, 'refresh']);
// Route::put('license_form/acceptRequest/{id}', [LicenseFormController::class, 'acceptRequest'])->middleware(['permission:license_form-accept_request']);

class LicencasFormsDataService {
  getAll(requestParams) {
    return http.get("/license_form/index", {params : requestParams});
  }
  uploadAuthorizationDocument(data) {
    return http.post("/authorization/store", data);
  }
  getAllRequests(requestParams) {
    return http.get("/license_form/indexRequests", {params : requestParams});
  }
  acceptRequest(id) {
    return http.put(`/license_form/acceptRequest/${id}`);
  }
  create(data) {
    return http.post("/license_form/store", data);
  }
  show(id) {
    return http.get(`/license_form/show/${id}`);
  }
  update(id, data) {
    return http.put(`/license_form/update/${id}`, data);
  }
  renew(id, data) {
    return http.put(`/license_form/renew/${id}`, data);
  }
  deactivate(id) {
    return http.put(`/license_form/inactive/${id}`);
  }
  activate(id) {
    return http.put(`/license_form/active/${id}`);
  }
  delete(id) {
    return http.delete(`/license_form/delete/${id}`);
  }
  downloadAuthorizationDocument(id) {
    return http.get(`/external/authorization/download/${id}`);
  }

}
export default new LicencasFormsDataService();