import http from "../http-common";

// Route::get('report/index', [ReportController::class, 'index'])->middleware(['permission:report-index']);

class RelatoriosDataService {
  getAll(requestParams) {
    return http.get("/report/index", {params : requestParams});
  }
  generate(data) {
    return http.post("/report/generate", data);
  }
  csv(data) {
    return http.post("/report/csv", data);
  }
  pdf(data) {
    return http.post("/report/pdf", data);
  }
  // create(data) {
  //   // return http.post("/license/type/store", data);
  // }
  // show(id) {
  //   // return http.get(`/license/type/show/${id}`);
  // }
  // delete(id) {
  //   // return http.delete(`/license/type/delete/${id}`);
  // }
  // update(id, data) {
  //   // return http.put(`/license/type/update/${id}`, data);
  // }
}
export default new RelatoriosDataService();