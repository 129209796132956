<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <perfis-table />
      </div>
    </div>
    
  </div>
</template>

<script>
import PerfisTable from "./components/PerfisTable";
// import ProjectsTable from "./components/ProjectsTable";

export default {
  name: "perfis",
  components: {
    PerfisTable,
    // ProjectsTable,
  },
};
</script>
