import axios from "axios";
import { useToast } from "vue-toastification";
// import router from "./router";

const toast = useToast();
const url = process.env.VUE_APP_API_URL;
const frontUrl = process.env.VUE_APP_FRONT_URL;

console.log(process.env);

const axiosIntercept = axios.create({
  baseURL: url + "/api",
  headers: {
    "Content-type": "application/json",
  }
}); 

const requestHandler = request => {
  request.headers.Authorization = 'Bearer ' + localStorage.getItem("token");
  return request;
};

const responseHandler = response => {
  if (response.status === 401) {
    window.location = frontUrl + '/sign-in';

    
  }

  return response;
};

const errorHandler = async error => {
  if (error.response.status === 401) {     
      toast.error("Usuário ou senha não conferem! Verifique o preenchimento.", {
        timeout: 8000
      });
  }

  if (error.response.status === 422) {     
    toast.error("Usuário ou senha não conferem! Verifique o preenchimento.", {
      timeout: 8000
    });
  }

  if(error.response.status === 404) { 
    toast.error("Página não encontrada! Verifique se a url está correta.", {
      timeout: 8000
    });
  }
  return Promise.reject(error);
};


axiosIntercept.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

axiosIntercept.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default axiosIntercept;
