<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <usuarios-table />
      </div>
    </div>
    
  </div>
</template>

<script>
import UsuariosTable from "./components/UsuariosTable";
// import ProjectsTable from "./components/ProjectsTable";

export default {
  name: "usuarios",
  components: {
    UsuariosTable,
    // ProjectsTable,
  },
};
</script>
