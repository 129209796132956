<template>
    <div class="crud-modal">
        <div class="card card-plain">

                <div class="pb-0 card-header text-start"> 
                  <h3 class="font-weight-bolder">
                    Editar unidade de lotação
                  </h3>
                  <p class="mb-0">Ajuste os dados abaixo.</p>
                </div>
                <div class="card-body">
                  <form role="form" class="text-start">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Nome</label>
                            <soft-input
                            id="Nome"
                            type="text"
                            placeholder="Insira o nome..."
                            name="Nome"
                            v-model:value="unidade.name"
                            />
                        </div>
                        <!-- <div class="col-md-6">
                            <label for="">Status</label>
                            <select name="status" id="status" class="form-select form-control">
                                <option selected value="true">Habilitada</option>
                                <option value="false">Desabilitada</option>
                            </select>
                        </div> -->
                    </div>
                    <hr>
                    <div style="text-align: right;">
                        <a href="#" class="btn btn-success" @click="update">
                            Salvar
                        </a>
                        <a href="#" class="btn btn-default" @click="close">
                            Cancelar
                        </a>
                    </div>
                  </form>
                </div> 
              </div>
    </div>
</template>
<style>
    .modal-container {
        z-index: 93949349394349;
    }
    .modal-item {
        width: 50%;
    }
</style>
<script>
    import SoftInput from "@/components/SoftInput.vue";
    import {closeModal} from "jenesius-vue-modal"
    import UnidadesDeLotacaoDataService from "../../services/UnidadesDeLotacaoDataService";

    export default {
        data() {
            return {
                unidade: "",
            }
        },
        props: {
            idunidade: String
        },
        methods: {
            update() {
                UnidadesDeLotacaoDataService.update(this.idunidade, this.unidade)
                .then(response => {
                    console.log(response);
                    if(response.data.validation_error){
                        return;
                    } else {
                        this.$emit("update");
                        this.close();
                    }  
                })
                .catch(e => {
                    console.log(e);
                });
            },
            fetchUnidade() {
                UnidadesDeLotacaoDataService.show(this.idunidade)
                .then(response => {
                    this.unidade = response.data.undLotacao;
                })
                .catch(e => {
                    console.log(e);
                });
            },
            close() {
                closeModal();
            }
        },
        components: {
            SoftInput,
        },
        mounted() {
            this.fetchUnidade();
        }
    }
</script>