import http from "../http-common";


//  //userCRUD
//  Route::get('user/index', [UserController::class, 'index'])->middleware(['permission:user-index']);
//  Route::post('user/store', [UserController::class, 'store'])->middleware(['permission:user-store']);
//  Route::get('user/show/{id}', [UserController::class, 'show'])->middleware(['permission:user-show']);
//  Route::put('user/update/{id}', [UserController::class, 'update'])->middleware(['permission:user-update']);
//  Route::put('user/deactivate/{id}', [UserController::class, 'deactivate'])->middleware(['permission:user-deactivate']);
//  Route::put('user/activate/{id}', [UserController::class, 'activate'])->middleware(['permission:user-activate']);

//  //ProfileCRUD
//  Route::get('profile/index', [UserProfileController::class, 'index'])->middleware(['permission:profile-index']);
//  Route::post('profile/store', [UserProfileController::class, 'store'])->middleware(['permission:profile-store']);
//  Route::get('profile/show/{id}', [UserProfileController::class, 'show'])->middleware(['permission:profile-show']);
//  Route::put('profile/update/{id}', [UserProfileController::class, 'update'])->middleware(['permission:profile-update']);
//  Route::delete('profile/delete/{id}', [UserProfileController::class, 'delete'])->middleware(['permission:profile-delete']);

//  //und_lotacaoCRUD
//  Route::post('und_lotacao/store', [LotacaoUndController::class, 'store'])->middleware(['permission:und_lotacao-store']);
//  Route::get('und_lotacao/index', [LotacaoUndController::class, 'index'])->middleware(['permission:und_lotacao-index']);
//  Route::put('und_lotacao/update/{id}', [LotacaoUndController::class, 'update'])->middleware(['permission:und_lotacao-update']);
//  Route::put('und_lotacao/deactivate/{id}', [LotacaoUndController::class, 'deactivate'])->middleware(['permission:und_lotacao-deactivate']);
//  Route::put('und_lotacao/activate/{id}', [LotacaoUndController::class, 'activate'])->middleware(['permission:und_lotacao-activate']);
//  Route::get('und_lotacao/show/{id}', [LotacaoUndController::class, 'show'])->middleware(['permission:und_lotacao-show']);


class PerfisDataService {
  getAll(requestParams) {
    return http.get("/profile/index", {params : requestParams});
  }
  create(data) {
    return http.post("/profile/store", data);
  }
  show(id) {
    return http.get(`/profile/show/${id}`);
  }
  update(id, data) {
    return http.put(`/profile/update/${id}`, data);
  }
  delete(id) {
    return http.delete(`/profile/delete/${id}`);
  }
  deactivate(id) {
    return http.put(`/profile/deactivate/${id}`);
  }
  activate(id) {
    return http.put(`/profile/activate/${id}`);
  }

}
export default new PerfisDataService();