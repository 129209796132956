<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <h6>Lista de sub-tipos do tipo ({{ tipo.name }})</h6>
    </div>

    <div style="text-align:right; margin-right: 1%; margin-top: -3%;">
      <a href="#"
        class="btn btn-secondary"
        @click="redirectBack"
      >
        <font-awesome-icon icon="fa-solid fa-arrow-left" />
        &nbsp; Voltar
      </a>
      <a href="#"
        class="btn btn-success"
        @click="openCreateModal"
      >
        <font-awesome-icon icon="fa-solid fa-user-plus" />
        &nbsp; Novo registro
      </a>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Nome</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"> Ações</th>
            </tr>
          </thead>
          <tbody>
            <div style="text-align:center; height: 300px;" v-if="laravelData == ''">
              <h4 style="margin-top: 200px;">Ainda não existem sub-tipos cadastrados para esse tipo.</h4>
            </div>
            <!-- Fazer v-for no tr -->
            <tr v-for="(subtipo, index) in laravelData" :key="index">
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ subtipo.name }}</h6>
                  </div>
                </div>
              </td>
              <td class="align-middle" style="text-align:center;">
                <div class="mt-2">
                  <a
                    href="javascript:;"
                    class="font-weight-bold text-xs btn btn-secondary"
                    data-toggle="tooltip"
                    data-original-title="Edit user"
                    @click="redirectToFinalidades(subtipo._id)"
                    >
                    <font-awesome-icon icon="fa-solid fa-sitemap" />
                    Finalidades</a
                  >
                  <a
                    href="javascript:;"
                    class="font-weight-bold text-xs btn btn-default"
                    data-toggle="tooltip"
                    data-original-title="Edit user"
                    @click="openShowModal(subtipo._id)"
                    >
                    <font-awesome-icon icon="fa-solid fa-eye" />
                    Visualizar</a
                  >
                  <a
                    href="javascript:;"
                    class="font-weight-bold text-xs btn btn-info"
                    data-toggle="tooltip"
                    data-original-title="Edit user"
                    @click="openEditModal(subtipo._id)"
                    >
                    <font-awesome-icon icon="fa-solid fa-edit" />
                    Editar</a
                  >
                  <a
                    href="javascript:;"
                    class="font-weight-bold text-xs btn btn-danger"
                    data-toggle="tooltip"
                    data-original-title="Delete"
                    @click="remove(subtipo._id, index)"
                    >
                    <font-awesome-icon icon="fa-solid fa-trash-alt" />
                    Excluir</a
                  >
                </div>
              </td>
            </tr>
            <!-- end v-for -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { openModal } from "jenesius-vue-modal";
import SubtiposCreate from "./SubtiposCreate.vue";
import SubtiposEdit from "./SubtiposEdit.vue";
import SubtiposShow from "./SubtiposShow.vue";
import TiposDataService from "../../services/TiposDataService";
import { useToast } from "vue-toastification";
import router from "@/router";
import SubtiposDataService from "../../services/SubtiposDataService";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: "subtipos-table",
  data() {
    return {
      tipo: "",
      laravelData: "",
      params: {"page" : 1},
      url_id: "",
    };
  },
  methods: {
    getAllChildren() {
         TiposDataService.show(this.url_id)
        .then(response => {
          console.log(response.data);
          this.laravelData = response.data.subTypes;
          this.tipo = response.data.licenseType;
        })
        .catch(e => {
          console.log(e);
        });
      },
      redirectToFinalidades(_id) {
        router.push("/finalidadesporsubtipo/" + _id);
      },
      redirectBack() {
        router.push("/modelos");
      },
      remove(_id, index) {
        // Use sweetalert2
        this.$swal({
          title: 'Você tem certeza?',
          text: "Deseja mesmo excluir este sub-tipo?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim, desejo!',
          cancelButtonText: 'Não, cancelar!',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            this.toast.success("Sub-tipo excluído com sucesso!", {
              timeout: 6000
            });
            SubtiposDataService.delete(_id);
            this.laravelData.splice(index, 1);
            this.$swal(
              'Sub-tipo excluído!',
              'O sub-tipo foi excluído com sucesso.',
              'success'
            )
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === this.$swal.DismissReason.cancel
          ) {
            this.$swal(
              'Ação cancelada',
              'A exclusão foi cancelada.',
              'error'
            )
          }
        })
      },
      async openCreateModal() {
        const modal = await openModal(SubtiposCreate, {parentid: this.url_id});

        modal.on('create', () => {
            this.toast.success("Sub-tipo criado com sucesso!", {
              timeout: 6000
            });
            this.getAllChildren();
        })
      },
      async openEditModal(_id) {
        const modal = await openModal(SubtiposEdit, {subtipoid: _id});

        modal.on('update', () => {
            this.toast.success("Sub-tipo editado com sucesso!", {
              timeout: 6000
            });
            this.getAllChildren();
        })
      },
      openShowModal(_id) {
        openModal(SubtiposShow, {subtipoid: _id});
      },
  },
  components: {
  },
  mounted (){
    this.url_id = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
    this.getAllChildren();
  }
};
</script>
