import http from "../http-common";

  //  //license_subTypesCRUD
  //  Route::get('license/sub_type/index', [LicenseSubTypeController::class, 'index'])->middleware(['permission:license_sub_type-index']);
  //  Route::post('license/sub_type/store', [LicenseSubTypeController::class, 'store'])->middleware(['permission:license_sub_type-store']);
  //  Route::get('license/sub_type/show/{id}', [LicenseSubTypeController::class, 'show'])->middleware(['permission:license_sub_type-show']);
  //  Route::delete('license/sub_type/delete/{id}', [LicenseSubTypeController::class, 'delete'])->middleware(['permission:license_sub_type-delete']);
  //  Route::put('license/sub_type/update/{id}', [LicenseSubTypeController::class, 'update'])->middleware(['permission:license_sub_type-update']);

class SubtiposDataService {
  getAll(requestParams) {
    return http.get("/license/sub_type/index", {params : requestParams});
  }
  create(data) {
    return http.post("/license/sub_type/store", data);
  }
  show(id, requestparams) {
    return http.get(`/license/sub_type/show/${id}`, {params : requestparams});
  }
  delete(id) {
    return http.delete(`/license/sub_type/delete/${id}`);
  }
  update(id, data) {
    return http.put(`/license/sub_type/update/${id}`, data);
  }
  deleteField(id, idField) {
    return http.delete(`license/sub_type/${id}/field/${idField}`);
  }
}
export default new SubtiposDataService();