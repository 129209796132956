<template>
    <div>
      <canvas :bardata="bardata" id="bar-chart"></canvas>
    </div>
  </template>
  
  <script>
    import Chart from 'chart.js/auto'

  export default {
    name: 'BarChart',
    props: {
        bardata: {
            type: Object,
        }
    },
    data() {
      return {
        chart: ""
      }
    },
    watch: {
    chart() {
      console.log("mudou")
      console.log(this.bardata)
    }
  },
    mounted() {
      const ctx = document.getElementById('bar-chart');
      this.chart = new Chart(ctx, this.bardata);
    }
  }
  </script>