<template>
    <div class="crud-modal">
        <div class="card card-plain">

                <div class="pb-0 card-header text-start"> 
                  <h3 class="font-weight-bolder">
                    Detalhes do perfil
                  </h3>
                  <!-- <p class="mb-0">Insira os dados abaixo.</p> -->
                </div>
                <div class="card-body">
                  <form role="form" class="text-start">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Nome</label>
                            <p>{{ perfil.name }}</p>
                        </div>
                    </div>
                    <hr>
                    <div class="row" style="overflow:auto;">
                        <div class="col-md-2">
                            <h4>Usuários</h4>
                            <div v-for="(permissao, index) in perfil.permissions" :key="index">
                                <p v-if="permissao.includes('user')" >{{ returnFriendlyPermissionName(permissao) }}</p>
                                <p v-if="permissao.includes('admin')" >{{ returnFriendlyPermissionName(permissao) }}</p>
                            </div>
                        </div>
                        <!-- end usuarios -->
                        <!-- start unidades de lotação -->
                        <div class="col-md-2">
                            <h4>Unidades de lotação</h4>
                            <div v-for="(permissao, index) in perfil.permissions" :key="index">
                                <p v-if="permissao.includes('und_lotacao')" >{{ returnFriendlyPermissionName(permissao) }}</p>
                                <p v-if="permissao.includes('admin')" >{{ returnFriendlyPermissionName(permissao) }}</p>
                            </div>
                        </div>
                        <!-- end unidades de lotação -->
                        <!-- start profiles -->
                        <div class="col-md-2">
                            <h4>Perfis / Permissões</h4>
                            <div v-for="(permissao, index) in perfil.permissions" :key="index">
                                <p v-if="permissao.includes('profile')" >{{ returnFriendlyPermissionName(permissao) }}</p>
                                <p v-if="permissao.includes('admin')" >{{ returnFriendlyPermissionName(permissao) }}</p>
                            </div>
                        </div>
                        <!-- end profiles -->
                        <!-- start profiles -->
                        <div class="col-md-2">
                            <h4>Modelos de licença</h4>
                            <div v-for="(permissao, index) in perfil.permissions" :key="index">
                                <p v-if="permissao.includes('license-')" >{{ returnFriendlyPermissionName(permissao) }}</p>
                                <p v-if="permissao.includes('admin')" >{{ returnFriendlyPermissionName(permissao) }}</p>
                            </div>
                        </div>
                        <!-- end profiles -->
                        <!-- licenseform -->
                        <div class="col-md-2">
                            <h4>Licenças (formulário)</h4>
                            <div v-for="(permissao, index) in perfil.permissions" :key="index">
                                <p v-if="permissao.includes('license_form')" >{{ returnFriendlyPermissionName(permissao) }}</p>
                                <p v-if="permissao.includes('admin')" >{{ returnFriendlyPermissionName(permissao) }}</p>
                            </div>
                        </div>
                        <!-- end licenseform -->
                    </div>
                    <div class="row" style="overflow:auto;">
                        <!-- types -->
                        <!-- <div class="col-md-2">
                            <h4>Modelos de tipo</h4>
                            <div v-for="(permissao, index) in perfil.permissions" :key="index">
                                <p v-if="permissao.includes('license_type')" >{{ returnFriendlyPermissionName(permissao) }}</p>
                                <p v-if="permissao.includes('admin')" >{{ returnFriendlyPermissionName(permissao) }}</p>
                            </div>
                        </div> -->
                        <!-- end types -->
                        <!-- subtypes -->
                        <!-- <div class="col-md-2">
                            <h4>Modelos de sub-tipo</h4>
                            <div v-for="(permissao, index) in perfil.permissions" :key="index">
                                <p v-if="permissao.includes('license_sub_type')" >{{ returnFriendlyPermissionName(permissao) }}</p>
                                <p v-if="permissao.includes('admin')" >{{ returnFriendlyPermissionName(permissao) }}</p>
                            </div>
                        </div> -->
                        <!-- end subtypes -->
                        <!-- finality -->
                        <!-- <div class="col-md-2">
                            <h4>Modelos de finalidade</h4>
                            <div v-for="(permissao, index) in perfil.permissions" :key="index">
                                <p v-if="permissao.includes('license_finality')" >{{ returnFriendlyPermissionName(permissao) }}</p>
                                <p v-if="permissao.includes('admin')" >{{ returnFriendlyPermissionName(permissao) }}</p>
                            </div>
                        </div> -->
                        <!-- end finality -->
                    </div>
                    <hr>
                    <div style="text-align: right;">
                        <a href="#" class="btn btn-default" @click="close">
                            Fechar
                        </a>
                    </div>
                  </form>
                </div> 
              </div>
    </div>
</template>
<style>
    .modal-container {
        z-index: 93949349394349;
    }
    .modal-item {
        width: 50%;
    }
</style>
<script>
    // import SoftInput from "@/components/SoftInput.vue";
    import {closeModal} from "jenesius-vue-modal"
    import PerfisDataService from "../../services/PerfisDataService";
    // import SoftCheckbox from "@/components/SoftCheckbox.vue";

    export default {
        data() {
            return {
                perfil: "",
                permissions: ""
            }
        },
        props: {
            idperfil: String
        },
        methods: {
            fetchPerfil() {
                PerfisDataService.show(this.idperfil)
                .then(response => {
                    // console.log(response.data.profile.permissions);
                    this.perfil = response.data.profile;
                })
                .catch(e => {
                    console.log(e);
                });
            },
            returnFriendlyPermissionName(permission){
                switch (permission) {
                    // Usuarios
                    case "user-index":
                        return "Ver listagem";
                    case "user-store":
                        return "Cadastrar";
                    case "user-update":
                        return "Editar";
                    case "user-deactivate":
                        return "Desativar";
                    case "user-activate":
                        return "Ativar";
                    // Unidades
                    case "und_lotacao-index":
                        return "Ver listagem";
                    case "und_lotacao-store":
                        return "Cadastrar";
                    case "und_lotacao-update":
                        return "Editar";
                    case "und_lotacao-deactivate":
                        return "Desativar";
                    case "und_lotacao-activate":
                        return "Ativar";
                    // Perfis
                    case "profile-index":
                        return "Ver listagem";
                    case "profile-store":
                        return "Cadastrar";
                    case "profile-update":
                        return "Editar";
                    case "profile-delete":
                        return "Excluir";
                    // Modelos
                    case "license-index":
                        return "Ver listagem";
                    case "license-store":
                        return "Cadastrar"
                    case "license-show":
                        return "Ver detalhes"
                    case "license-update":
                        return "Editar"
                    case "license-deactivate":
                        return "Desativar"
                    case "license-activate":
                        return "Ativar"
                    // Tipos
                    case "license_type-index":
                        return "Ver listagem";
                    case "license_type-store":
                        return "Cadastrar";
                    case "license_type-show":
                        return "Ver detalhes";
                    case "license_type-update":
                        return "Editar";
                    case "license_type-delete":
                        return "Excluir";
                    // Sub-tipos
                    case "license_sub_type-index":
                        return "Ver listagem";
                    case "license_sub_type-store":
                        return "Cadastrar";
                    case "license_sub_type-show":
                        return "Ver detalhes";
                    case "license_sub_type-update":
                        return "Editar";
                    case "license_sub_type-delete":
                        return "Excluir";
                    // Finalidades
                    case "license_finality-index":
                        return "Ver listagem";
                    case "license_finality-store":
                        return "Cadastrar";
                    case "license_finality-show":
                        return "Ver detalhes";
                    case "license_finality-update":
                        return "Editar";
                    case "license_finality-delete":
                        return "Excluir";
                    // Licenseforms
                    case "license_form-index":
                        return "Ver listagem";
                    case "license_form-store":
                        return "Cadastrar";
                    case "license_form-show":
                        return "Ver detalhes";
                    case "license_form-delete":
                        return "Excluir";
                    case "license_form-active":
                        return "Habilitar";
                    case "license_form-inactive":
                        return "Desabilitar";
                    case "license_form-update":
                        return "Editar";
                    case "license_form-request":
                        return "Ver solicitações";
                    case "license_form-renew":
                        return "Renovar";
                    case "license_form-accept_request":
                        return "Aceitar solicitação";
                    case "admin":
                        return "Todas as permissões habilitadas"
                    default:
                        return "TBD"
                }
            },
            addOrRemovePermission(permissionParam) {
                let pos = this.profile.permissions.indexOf(permissionParam)
                if (pos == -1){
                    this.profile.permissions.push(permissionParam);
                } else {
                    this.profile.permissions.splice(pos, 1);
                }
            },
            close() {
                closeModal();
            },
        },
        mounted() {
            this.fetchPerfil();
        }
    }
</script>