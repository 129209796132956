<template>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <tipos-table />
        </div>
      </div>
      
    </div>
  </template>
  
  <script>
  import TiposTable from "./components/TiposTable.vue";
  
  export default {
    name: "tipos",
    components: {
      TiposTable,
    },
  };
  </script>
  