import http from "../http-common";

// Route::post('und_lotacao/store', [LotacaoUndController::class, 'store'])->middleware(['permission:und_lotacao-store']);
// Route::get('und_lotacao/index', [LotacaoUndController::class, 'index'])->middleware(['permission:und_lotacao-index']);
// Route::put('und_lotacao/update/{id}', [LotacaoUndController::class, 'update'])->middleware(['permission:und_lotacao-update']);
// Route::put('und_lotacao/deactivate/{id}', [LotacaoUndController::class, 'deactivate'])->middleware(['permission:und_lotacao-deactivate']);
// Route::put('und_lotacao/activate/{id}', [LotacaoUndController::class, 'activate'])->middleware(['permission:und_lotacao-activate']);

class UnidadesDeLotacaoDataService {
  getAll(requestParams) {
    return http.get("/und_lotacao/index", {params : requestParams});
  }
  create(data) {
    return http.post("/und_lotacao/store", data);
  }
  show(id) {
    return http.get(`/und_lotacao/show/${id}`);
  }
  update(id, data) {
    return http.put(`/und_lotacao/update/${id}`, data);
  }
  deactivate(id) {
    return http.put(`/und_lotacao/deactivate/${id}`);
  }
  activate(id) {
    return http.put(`/und_lotacao/activate/${id}`);
  }

}
export default new UnidadesDeLotacaoDataService();