import http from "../http-external";

class AuthDataService {
  login(cpfparam, passwordparam) {
    return http.post("/login", {cpf: cpfparam, password: passwordparam});
  }

  refresh() {
    return http.get("/refreshAuth");
  } 

}
export default new AuthDataService();