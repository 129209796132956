import http from "../http-external";

// Route::get('external/authorization/download/{id}', [AuthorizationDocumentController::class, 'download']);

//externalUser
// Route::get('external/license_form/index', [LicenseFormExternalController::class, 'index']);

// Route::get('external/license/index', [LicenseExternalController::class, 'index']);
// Route::get('external/license/show/{id}', [LicenseExternalController::class, 'show']);

// Route::get('external/license/type/index', [LicenseTypeExternalController::class, 'index']);
// Route::get('external/license/type/show/{id}', [LicenseTypeExternalController::class, 'show']);

// Route::get('external/license/subType/index', [LicenseTypeExternalController::class, 'index']);
// Route::get('external/license/subType/show/{id}', [LicenseSubTypeExternalController::class, 'show']);

// Route::get('external/license/finality/index', [LicenseFinalityExternalController::class, 'index']);
// Route::get('external/license/finality/show/{id}', [LicenseFinalityExternalController::class, 'show']);

class LicencasFormsExternalDataService {
  downloadAuthorizationDocument(id) {
    return http.get(`/external/authorization/download/${id}`);
  }
  getAllLicenseForms(requestParams) {
    return http.get("/external/license_form/index", {params : requestParams});
  }
  getAllLicenses(requestParams) {
    return http.get("/external/license/index", {params : requestParams});
  }
  showLicense(id) {
    return http.get(`/external/license/show/${id}`);
  }
  getAllTypes(requestParams) {
    return http.get("/external/license/type/index", {params : requestParams});
  }
  showType(id) {
    return http.get(`/external/license/type/show/${id}`);
  }
  getAllSubTypes(requestParams) {
    return http.get("/external/license/subType/index", {params : requestParams});
  }
  showSubType(id) {
    return http.get(`/external/license/subType/show/${id}`);
  }
  getAllFinalities(requestParams) {
    return http.get("/external/license/finality/index", {params : requestParams});
  }
  showFinality(id) {
    return http.get(`/external/license/finality/show/${id}`);
  }
}
export default new LicencasFormsExternalDataService();